import {
  fromJS,
} from 'immutable';

import {
  ACTION_TYPES,
} from './actions';

const initialState = fromJS({
  error: null,
  location: null,
});

export default function errors(state = initialState, action = {}) {
  switch (action.type) {
    case ACTION_TYPES.SET_ERROR: {
      return state.merge(action.payload);
    }

    default:
      return state;
  }
}
