import {
  Record,
} from 'immutable';

import {
  EMAIL_FIELD_TYPE,
} from 'Modules/Proposals/constants';

const defaults = {
  emailAddress: null,
  emailFieldType: EMAIL_FIELD_TYPE.TO,
  name: '',
};

export default class Recipient extends Record(defaults) {}
