import {
  takeEvery,
} from 'redux-saga/effects';

import {
  actionTypes,
} from 'Modules/Proposals/actions';
import handleApplyMeetingType from 'Modules/Proposals/sagas/handleApplyMeetingType';
import handleTimeSlotCreation from 'Modules/Proposals/sagas/handleTimeSlotCreation';
import handleUpdateSender from 'Modules/Proposals/sagas/handleUpdateSender';
import analyticsWatchers from 'Modules/Proposals/sagas/analytics';

function* watchHandleTimeSlotCreation() {
  yield takeEvery(actionTypes.CREATE_TIME_SLOTS, handleTimeSlotCreation);
}

function* watchHandleUpdateSender() {
  yield takeEvery(actionTypes.UPDATE_SENDER, handleUpdateSender);
}

function* watchApplyMeetingType() {
  yield takeEvery(actionTypes.APPLY_MEETING_TYPE, handleApplyMeetingType);
}

export {
  watchApplyMeetingType,
  watchHandleTimeSlotCreation,
  watchHandleUpdateSender,
  analyticsWatchers,
};
