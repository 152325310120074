import { initialize } from 'launchdarkly-js-client-sdk';

import logError from 'Modules/Shared/logError';

let ldClient;

export async function initializeLdClient(ldUser) {
  const clientSideId = process.env.LAUNCH_DARKLY_CLIENT_SIDE_ID || '5e8539599e80da08315d4440';
  try {
    ldClient = initialize(clientSideId, ldUser);
    await ldClient.waitForInitialization;
  } catch (e) {
    logError(e);
    ldClient = {
      variation: () => false,
      on: () => {},
      off: () => {},
    };
  }
}

export const getLDClient = () => ldClient;
