import randomcolor from 'randomcolor';
import HttpStatusCodes from 'http-status-codes';
import GrooveHTTPClient, {
  MeetingProposals as MeetingProposalsClient,
  Users as UsersClient,
  Scheduler as SchedulerClient,
  Settings as SettingsClient,
  HTTPError,
} from '@groove-labs/groove-http-client';
import {
  actions,
  listToOrderedMap,
} from '@groove-labs/groove-ui';
import {
  eventChannel,
} from 'redux-saga';
import {
  Map,
  OrderedMap,
  List,
} from 'immutable';
import Raven from 'raven-js';
import {
  all,
  call,
  delay,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
  race,
  select,
} from 'redux-saga/effects';

import determineTimeSlots from 'Modules/Proposals/sagas/determineTimeSlots';
import {
  actionTypes,
  deleteTimeSlots,
  setAllUsers,
  setComposeWidgetId,
  setRecipients,
  setCurrentUser,
  setExtensionAuth,
  setMeetingSlug,
  setMeetingTypes,
  setSender,
  setUserColors,
  setUserEvents,
  successCreatingMeetingProposal,
  revertOtherAttendeeIds,
  setTimeSlots,
  addUserEvents,
  setOrganizationBrand,
  setUserZoomEmail,
} from 'Modules/Proposals/actions';
import {
  PROPOSALS_GROUP_ID, PRIMARY_EVENT_COLOR,
} from 'Modules/Proposals/constants';
import {
  getTitle,
  getLocation,
  getVideoConferencing,
  getComposeWidgetId,
  getCurrentUser,
  getDescription,
  getPreviousDuration,
  getTimeZone,
  getOtherAttendeeIds,
  getSender,
  getAvailableTimeSlots,
  getCurrentTimeRange,
  getUserColors,
  getIsDoubleBooked,
  getInsertAsPlainText,
} from 'Modules/Proposals/selectors';
import transformEvents from 'Utils/transformEvents';
import { setIsLoading } from 'Modules/App/actions';
import {
  formatTimeSlot,
} from 'Modules/Proposals/utils';
import identifyQueryParameters from 'Utils/identifyQueryParameters';
import generateTimeSlotsHTML from 'Modules/Proposals/utils/generateTimeSlotsHTML';
import { pushSnackbarMessage } from 'Modules/Shared/actions/snackbar';
import {
  FailedRequestError,
  InvalidGoogleCalendarCredentialsError,
  NoComposeWindowIdError,
  UnableToGetExtensionAuthenticationDataError,
} from 'Modules/Proposals/errors';
import closeWindow from 'Utils/closeWindow';
import {
  watchApplyMeetingType,
  watchHandleTimeSlotCreation,
  watchHandleUpdateSender,
  analyticsWatchers,
} from 'Modules/Proposals/sagas/watchers';
import User from 'Modules/Proposals/data/User';
import { initializeLdClient } from 'Utils/ldClient';
import handleFetchUserZoomEmail from 'Modules/Proposals/sagas/handleFetchUserZoomEmail';
import getMeetingTypes from 'Modules/Proposals/sagas/getMeetingTypes';

const {
  UPDATE_FIELD_VALUE: UPDATE_FIELD_VALUE_ACTION_TYPE,
} = actions.form.actionTypes;

const createIncomingActionEventChannel = () => (
  eventChannel((emitter) => {
    const handler = (message) => {
      // Ensure the message came from this same window
      if (message.source !== window) {
        return;
      }

      // Ensure the message is a FSA from Chrome extension Dialer
      const action = message.data;
      if (!action) {
        return;
      }

      emitter(action);
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  })
);

function* waitForExtensionToken() {
  const channel = createIncomingActionEventChannel();

  while (true) {
    const {
      type,
      payload,
    } = yield take(channel);

    if (type === 'HYDRATE_GROOVE_EXTENSION_AUTH') {
      yield put(setExtensionAuth(payload));
      return payload;
    }
  }
}

function* fetchEvents({ type = '' } = {}) {
  yield put({ type: actionTypes.FETCH_EVENTS.BEGIN });
  const [
    sender,
    currentUser,
    otherAttendeeIds,
    currentTimeRange,
  ] = yield all([
    select(getSender),
    select(getCurrentUser),
    select(getOtherAttendeeIds, { groupId: PROPOSALS_GROUP_ID }),
    select(getCurrentTimeRange),
  ]);
  const allUserIds = otherAttendeeIds.add(sender.get('id'));

  try {
    const events = yield* SchedulerClient.getEvents({
      userIds: allUserIds.toJS(),
      year: currentTimeRange.get('start').isoWeekYear(),
      week: currentTimeRange.get('start').isoWeek(),
    });

    const eventItems = transformEvents({
      events: events.data.results,
      currentUser,
    });

    // make sure to clear out the reducer in the case of
    // adding/removing attendees or setting the sender
    // so that we can repopulate with a fresh list of events
    if ([actionTypes.SET_OTHER_ATTENDEE_IDS, actionTypes.SET_SENDER].includes(type)) {
      yield put(setUserEvents(new List()));
    }

    yield put(addUserEvents(eventItems));
    yield put({ type: actionTypes.FETCH_EVENTS.SUCCESS });
    const transformedTimeSlots = yield* determineTimeSlots();
    yield put(setTimeSlots(transformedTimeSlots));
  } catch (e) {
    yield put({ type: actionTypes.FETCH_EVENTS.FAILURE });

    if (e instanceof HTTPError) {
      // if the endpoint 400'd as a result of changing attendee ids, it must be because the user we added
      // has bad google creds, so we remove them and fail gracefully.
      if (e.response.get('status') === HttpStatusCodes.BAD_REQUEST) {
        if (type === actionTypes.SET_OTHER_ATTENDEE_IDS) {
          yield put(pushSnackbarMessage({ message: 'Unable to fetch events for this user.' }));
          yield put(revertOtherAttendeeIds());
        } else {
          throw new InvalidGoogleCalendarCredentialsError();
        }
      } else {
        throw new FailedRequestError();
      }
    } else {
      throw e;
    }
  }
}

function* updateUserColors() {
  const currentUserColors = yield select(getUserColors);
  const sender = yield select(getSender);
  const otherAttendeeIds = yield select(getOtherAttendeeIds, { groupId: PROPOSALS_GROUP_ID });

  const userColors = otherAttendeeIds.toList()
    .map(userId => new Map({
      userId,
      color: randomcolor({ luminosity: 'light' }),
    }))
    .push(new Map({
      userId: sender.get('id'),
      color: PRIMARY_EVENT_COLOR,
    }));

  const updatedUserColors = listToOrderedMap(userColors, userColor => userColor.get('userId')).map(value => value.get('color'));

  yield put(setUserColors(updatedUserColors.merge(currentUserColors)));
}

function* handleCreateMeetingProposal() {
  const [
    sender,
    title,
    location,
    videoConferencing,
    description,
    timeZone,
    otherAttendeeIds,
    timeSlots,
    composeWidgetId,
    doubleBookable,
    insertAsPlainText,
  ] = yield all([
    select(getSender),
    select(getTitle, { groupId: PROPOSALS_GROUP_ID }),
    select(getLocation, { groupId: PROPOSALS_GROUP_ID }),
    select(getVideoConferencing, { groupId: PROPOSALS_GROUP_ID }),
    select(getDescription, { groupId: PROPOSALS_GROUP_ID }),
    select(getTimeZone, { groupId: PROPOSALS_GROUP_ID }),
    select(getOtherAttendeeIds, { groupId: PROPOSALS_GROUP_ID }),
    select(getAvailableTimeSlots),
    select(getComposeWidgetId),
    select(getIsDoubleBooked),
    select(getInsertAsPlainText),
  ]);

  const timeZoneName = timeZone.get('value');

  const formattedTimeSlots = timeSlots.toJS().map(timeSlot => formatTimeSlot({
    timeSlot,
    timeZoneName,
  }));

  let proposalResponse;

  try {
    proposalResponse = yield call(
      MeetingProposalsClient.create,
      {
        description,
        location,
        videoConferencing,
        title,
        timeZoneName,
        doubleBookable,
        senderId: sender.get('id'),
        timeSlots: formattedTimeSlots,
      },
    );
  } catch (e) {
    throw new FailedRequestError();
  }

  yield put(successCreatingMeetingProposal(proposalResponse.data));

  try {
    if (!otherAttendeeIds.isEmpty()) {
      yield call(
        MeetingProposalsClient.updateAttendees,
        {
          proposalId: proposalResponse.data.id,
          attendeeIds: otherAttendeeIds.toJS(),
        },
      );
    }
  } catch (e) {
    throw new FailedRequestError();
  }

  const inlinedHtml = generateTimeSlotsHTML(insertAsPlainText);

  window.postMessage({
    type: 'INJECT_INLINED_HTML',
    meta: { source: 'scheduler' },
    payload: {
      inlinedHtml,
      composeWidgetId,
    },
  }, window.location.origin);

  closeWindow();
}

function* handleDurationChange({ payload: { value: updatedDuration } }) {
  const previousDuration = yield select(getPreviousDuration, { groupId: PROPOSALS_GROUP_ID });
  if (updatedDuration !== previousDuration) {
    yield put(deleteTimeSlots());
  }
}

function* watchEventFetchTriggers() {
  yield takeLatest([
    actionTypes.SET_OTHER_ATTENDEE_IDS,
    actionTypes.SET_SENDER,
    actionTypes.SET_CURRENT_TIME_RANGE,
  ], fetchEvents);
}

function* watchSetOtherAttendeeIds() {
  yield takeEvery(actionTypes.SET_OTHER_ATTENDEE_IDS, updateUserColors);
}

function* watchBeginCreatingMeetingProposal() {
  yield takeEvery(actionTypes.CREATE_MEETING_PROPOSAL.BEGIN, handleCreateMeetingProposal);
}

function* watchDurationFormFieldChange() {
  yield takeEvery(action => action.type === UPDATE_FIELD_VALUE_ACTION_TYPE
    && action.payload.groupId === PROPOSALS_GROUP_ID
    && action.payload.fieldId === 'duration',
  handleDurationChange,
  );
}

export default function* root() {
  const {
    extensionAuthenticationData,
  } = yield race({
    extensionAuthenticationData: call(waitForExtensionToken),
    timeout: delay(5000),
  });

  if (!extensionAuthenticationData) {
    throw new UnableToGetExtensionAuthenticationDataError();
  }

  const {
    grooveEngineRootUrl,
    grooveEngineUserEmail,
    grooveEngineUserId,
    grooveExtensionToken,
  } = extensionAuthenticationData;

  Raven.setUserContext({
    ...(grooveEngineUserEmail && { email: grooveEngineUserEmail }),
    ...(grooveEngineUserId && { userId: grooveEngineUserId }),
  });

  const queryParameters = identifyQueryParameters();
  if (queryParameters && queryParameters.composeWidgetId) {
    yield put(setComposeWidgetId(queryParameters.composeWidgetId));
    yield put(setRecipients(JSON.parse(queryParameters.recipients || '[]')));
  } else {
    throw new NoComposeWindowIdError();
  }

  window.GROOVE_ENGINE_BASE_URL = grooveEngineRootUrl || window.GROOVE_ENGINE_BASE_URL;

  GrooveHTTPClient.configure({
    baseURL: window.GROOVE_ENGINE_BASE_URL,
    defaultHeaders: {
      grooveAuthorization: grooveExtensionToken,
    },
  });

  let user;
  let users;
  let ldUser;
  let meetingTypes;
  let schedulerSettings;
  let organizationBrand;
  let zoomEmail;

  try {
    [
      user,
      users,
      ldUser,
      meetingTypes,
      schedulerSettings,
      organizationBrand,
      zoomEmail,
    ] = yield all([
      UsersClient.getCurrentUser(),
      UsersClient.getBasicUsers(),
      UsersClient.getLdUser(),
      call(getMeetingTypes),
      SettingsClient.getScheduler(),
      SchedulerClient.getBranding(),
      handleFetchUserZoomEmail(window.GROOVE_ENGINE_BASE_URL, grooveExtensionToken),
    ]);
  } catch (e) {
    throw new FailedRequestError();
  }

  const currentUser = User.fromHTTPResponse(user.data);
  const sender = User.fromHTTPResponse(user.data);
  const allUsers = new OrderedMap(users.data.map(userData => ([
    userData.id,
    User.fromHTTPResponse(userData),
  ])));
  yield call(initializeLdClient, ldUser.data);

  yield all([
    put(setCurrentUser(currentUser)),
    put(setSender(sender)),
    put(setAllUsers(allUsers)),
    put(setMeetingTypes(meetingTypes.data)),
    put(setMeetingSlug(schedulerSettings.data.link)),
    put(setOrganizationBrand(organizationBrand.data)),
    put(setUserZoomEmail(zoomEmail)),
  ]);

  yield* fetchEvents();
  yield* updateUserColors();

  yield put(setIsLoading(false));

  yield all([
    fork(watchApplyMeetingType),
    fork(watchEventFetchTriggers),
    fork(watchSetOtherAttendeeIds),
    fork(watchBeginCreatingMeetingProposal),
    fork(watchDurationFormFieldChange),
    fork(watchHandleTimeSlotCreation),
    fork(watchHandleUpdateSender),
    fork(analyticsWatchers),
  ]);
}
