import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  withStyles,
} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import React, { Component } from 'react';

import SubmitFeedbackButton from 'Modules/Error/containers/Error/SubmitFeedbackButton';
import ConfusedMan from 'Modules/Error/components/ConfusedMan';

const buttonWrapperStyles = {
  marginTop: 24,
  display: 'flex',
};

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
  },
  headlineText: {
    marginTop: 27,
    marginBottom: 15,
  },
  messageWrapper: {
    textAlign: 'center',
  },
  multipleButtonWrapper: {
    ...buttonWrapperStyles,
    justifyContent: 'flex-end',
  },
  multipleButtonsReloadButton: {
    marginRight: 16,
  },
  singleButtonWrapper: {
    ...buttonWrapperStyles,
    justifyContent: 'center',
  },
});

@withStyles(styles)
class Error extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      headlineText: PropTypes.string.isRequired,
      messageWrapper: PropTypes.string.isRequired,
      multipleButtonWrapper: PropTypes.string.isRequired,
      multipleButtonsReloadButton: PropTypes.string.isRequired,
      root: PropTypes.string.isRequired,
      singleButtonWrapper: PropTypes.string.isRequired,
    }).isRequired,
    recoveryButtonOnClick: PropTypes.func.isRequired,

    headlineText: PropTypes.string,
    icon: PropTypes.node,
    messageBodyText: PropTypes.string,
    recoveryButtonText: PropTypes.string,
  };

  static defaultProps = {
    headlineText: 'Whoops!',
    icon: <ConfusedMan />,
    messageBodyText: 'Something went wrong. We logged the error and are looking into it.',
    recoveryButtonText: 'Reload Previous Page',
  }

  getButtonClasses = () => (
    this.showRecoveryButton()
      && this.showSubmitFeedbackButton()
      && this.props.classes.multipleButtonWrapper
  ) || this.props.classes.singleButtonWrapper

  getRecoveryButtonProps = () => ({
    color: 'primary',
    ...(this.showRecoveryButton() && { onClick: this.props.recoveryButtonOnClick }),
    ...(!this.showSubmitFeedbackButton() && { variant: 'raised' }),
    ...(this.showSubmitFeedbackButton() && { className: this.props.classes.multipleButtonsReloadButton }),
  })

  showRecoveryButton = () => this.props.location != null && this.props.location.indexOf('/error') < 0

  showSubmitFeedbackButton = () => !!Raven.lastEventId()

  render() {
    const {
      classes,
      icon,
      headlineText,
      messageBodyText,
      recoveryButtonText,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.messageWrapper}>
          { icon }
          <Typography
            classes={{ root: classes.headlineText }}
            variant="headline"
          >
            { headlineText }
          </Typography>
          <Typography variant="body1">
            { messageBodyText }
          </Typography>
          <div className={this.getButtonClasses()}>
            {
              this.showRecoveryButton()
              && (
                <Button {...this.getRecoveryButtonProps()}>
                  { recoveryButtonText }
                </Button>
              )
            }
            {
              this.showSubmitFeedbackButton()
              && <SubmitFeedbackButton />
            }
          </div>
        </div>
      </div>
    );
  }
}

export default Error;
