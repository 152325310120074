import { combineReducers } from 'redux-immutable';
import { reducers as grooveUIReducers } from '@groove-labs/groove-ui';

import { reducer as app } from 'Modules/App';
import { reducer as error } from 'Modules/Error';
import { reducer as proposals } from 'Modules/Proposals';

export default combineReducers({
  app,
  error,
  proposals,
  ...grooveUIReducers,
});
