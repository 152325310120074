import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import ImmutablePropTypes from 'react-immutable-proptypes';

import TimeSlot from 'Modules/Shared/emailComponents/TimeSlot';
import CreatedTimeSlot from 'Modules/Proposals/data/CreatedTimeSlot';

const styles = {
  root: {
    width: 150,
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 16,
  },
  groupHeader: {
    textTransform: 'uppercase',
    paddingBottom: 8,
  },
};

const TimeSlotGroup = ({
  classes, day, timeSlots, timeZoneName,
}) => {
  return (
    <div
      key={day}
      className={classes.root}
    >
      <Typography
        type="subheading"
        className={classes.groupHeader}
      >
        {day}
      </Typography>
      {timeSlots.map(timeSlot => (
        <TimeSlot
          key={timeSlot.start.tz(timeZoneName).toString()}
          timeSlot={timeSlot}
        />
      )).valueSeq()}
    </div>
  );
};

TimeSlotGroup.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  day: PropTypes.string.isRequired,
  timeSlots: ImmutablePropTypes.listOf(CreatedTimeSlot).isRequired,
  timeZoneName: PropTypes.string.isRequired,
};

export default withStyles(styles)(TimeSlotGroup);
