import {
  Record,
} from 'immutable';

const defaults = {
  useCustomBranding: false,
  hexColor: null,
  headerPixelHeight: null,
  logoUrl: null,
};

export default class OrganizationBrand extends Record(defaults) {
  static fromHTTPResponse(data) {
    return new OrganizationBrand(data);
  }
}
