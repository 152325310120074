/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import CreatedTimeSlot from 'Modules/Proposals/data/CreatedTimeSlot';
import {
  getCreatedMeetingProposalTimeZoneName,
  getMeetingSlug,
  getOrganizationBrand,
} from 'Modules/Proposals/selectors';
import { convertTimeToTimeZone } from 'Modules/Proposals/utils';


@connect(state => ({
  meetingSlug: getMeetingSlug(state),
  timeZoneName: getCreatedMeetingProposalTimeZoneName(state),
  organizationBrand: getOrganizationBrand(state),
}))
class TimeSlotPlainText extends Component {
  static propTypes = {
    timeSlot: PropTypes.instanceOf(CreatedTimeSlot).isRequired,
    timeZoneName: PropTypes.string.isRequired,
  };

  getFormattedStartTime = () => {
    const {
      timeSlot,
      timeZoneName,
    } = this.props;

    return convertTimeToTimeZone({
      timeZoneName,
      time: timeSlot.start,
    }).format('h:mm');
  }

  getFormattedEndTime = () => {
    const {
      timeSlot,
      timeZoneName,
    } = this.props;

    return convertTimeToTimeZone({
      timeZoneName,
      time: timeSlot.end,
    }).format('h:mmA');
  }

  getFormattedTimeSlot = () => `${this.getFormattedStartTime()} - ${this.getFormattedEndTime()}`

  render() {
    return (
      <div>
        {' - '}
        { this.getFormattedTimeSlot() }
      </div>
    );
  }
}

export default TimeSlotPlainText;
