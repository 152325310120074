import '@babel/polyfill';
import { MuiThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import React from 'react';

import grooveTheme from 'Utils/grooveTheme';
import store from 'Redux/store';
import { ModuleRoot as App } from 'Modules/App';
import 'fullcalendar/dist/fullcalendar.css';
import '../css/fullcalendar-material.css';

if (process.env.NODE_ENV === 'development') {
  const {
    whyDidYouUpdate,
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  } = require('why-did-you-update');
  whyDidYouUpdate(React);
}

window.GROOVE_ENGINE_BASE_URL = process.env.GROOVE_ENGINE_BASE_URL;

ReactDOM.render((
  <MuiThemeProvider theme={grooveTheme}>
    <Provider store={store}>
      <App />
    </Provider>
  </MuiThemeProvider>
),
document.getElementById('root'));

document.body.style.overflow = 'hidden';
