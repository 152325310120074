import { grooveTheme } from '@groove-labs/groove-ui';
import {
  all,
  delay,
  fork,
  put,
  select,
  takeEvery,
} from 'redux-saga/effects';

import {
  actionTypes,
  setSnackbarOpen,
  shiftSnackbarMessage,
  setSnackbarClosed,
} from 'Modules/Shared/actions/snackbar';
import {
  getIsSnackbarOpen, getSnackbarQueue,
} from 'Modules/App/selectors';

function* pushSnackbarMessageHandler() {
  const isOpen = yield select(getIsSnackbarOpen);

  if (!isOpen) {
    yield put(setSnackbarOpen());
  }
}

function* pushRequestSnackbarCloseHandler() {
  yield put(setSnackbarClosed());
  yield delay(grooveTheme.transitions.leavingScreen);
  yield put(shiftSnackbarMessage());

  const queue = yield select(getSnackbarQueue);

  if (queue.size > 0) {
    yield put(setSnackbarOpen());
  }
}


function* watchPushSnackbarMessage() {
  yield takeEvery(actionTypes.PUSH_SNACKBAR_MESSAGE, pushSnackbarMessageHandler);
}

function* watchRequestSnackbarMessage() {
  yield takeEvery(actionTypes.REQUEST_SNACKBAR_CLOSE, pushRequestSnackbarCloseHandler);
}

// -------------- Exporting the root saga for integration with the store --------------
export default function* root() {
  yield all([
    fork(watchPushSnackbarMessage),
    fork(watchRequestSnackbarMessage),
  ]);
}
