import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';

import {
  beginCreatingMeetingProposal,
} from 'Modules/Proposals/actions';
import {
  showCreateMeetingProposalButton,
} from 'Modules/Proposals/selectors';
import closeWindow from 'Utils/closeWindow';

const styles = {
  root: {
    display: 'flex',
    padding: 16,
    flexShrink: 0,
  },
  buttonContainer: {
    marginLeft: 'auto',
  },
  cancelButton: {
    marginRight: 5,
  },
};

@connect(
  (state) => {
    return {
      showCreateMeetingProposalButton: showCreateMeetingProposalButton(state),
    };
  },
  {
    beginCreatingMeetingProposal,
  },
)
@withStyles(styles)
class SubmitButtons extends Component {
  static propTypes = {
    beginCreatingMeetingProposal: PropTypes.func.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
    }).isRequired,
    showCreateMeetingProposalButton: PropTypes.bool.isRequired,
  }

  handleCancelClick = () => closeWindow();

  handleInsertTimesClick = () => {
    const {
      beginCreatingMeetingProposal,
    } = this.props;

    beginCreatingMeetingProposal();
  }

  render() {
    const {
      classes,
      showCreateMeetingProposalButton,
    } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.cancelButton}
            onClick={this.handleCancelClick}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!showCreateMeetingProposalButton}
            onClick={this.handleInsertTimesClick}
            variant="raised"
          >
            Insert Times
          </Button>
        </div>
      </div>
    );
  }
}

export default SubmitButtons;
