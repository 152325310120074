import {
  FAILED_HTTP_REQUEST_ERROR_MESSAGE,
  NO_COMPOSE_WINDOW_ID_ERROR_MESSAGE,
  INVALID_GOOGLE_CALENDAR_CREDENTIALS,
  UNABLE_TO_GET_EXTENSION_AUTHENTICATION_DATA,
} from 'Modules/Error/constants/messages';


class NoComposeWindowIdError extends Error {
  constructor() {
    super(NO_COMPOSE_WINDOW_ID_ERROR_MESSAGE);

    this.name = NoComposeWindowIdError.name;
  }
}

class FailedRequestError extends Error {
  constructor() {
    super(FAILED_HTTP_REQUEST_ERROR_MESSAGE);

    this.name = FailedRequestError.name;
  }
}

class InvalidGoogleCalendarCredentialsError extends Error {
  constructor() {
    super(INVALID_GOOGLE_CALENDAR_CREDENTIALS);

    this.name = InvalidGoogleCalendarCredentialsError.name;
  }
}

class UnableToGetExtensionAuthenticationDataError extends Error {
  constructor() {
    super(UNABLE_TO_GET_EXTENSION_AUTHENTICATION_DATA);

    this.name = UnableToGetExtensionAuthenticationDataError.name;
  }
}

export {
  NoComposeWindowIdError,
  FailedRequestError,
  InvalidGoogleCalendarCredentialsError,
  UnableToGetExtensionAuthenticationDataError,
};

