import {
  Record,
  OrderedMap,
  fromJS,
  List,
} from 'immutable';
import momentTimezone from 'moment-timezone';
import { listToOrderedMap } from '@groove-labs/groove-ui';

import User from 'Modules/Proposals/data/User';
import Recipient from 'Modules/Proposals/data/Recipient';

export const statuses = Object.freeze({
  DRAFT: 'draft',
  SENT: 'sent',
  BOOKED: 'booked',
});

const defaults = {
  id: null,
  title: '',
  status: statuses.DRAFT,
  location: '',
  description: '',
  timeZoneName: '',
  timeSlots: new OrderedMap(),
  sender: new User(),
  recipients: new List(),
};

export default class MeetingProposal extends Record(defaults) {
  static STATUSES = statuses;

  static fromHTTPResponse(data) {
    const transformedTimeSlots = fromJS(data.timeSlots)
      .map(timeSlot => timeSlot
        .set('startsAt', momentTimezone(timeSlot.get('startsAt')).tz(data.timeZoneName))
        .set('endsAt', momentTimezone(timeSlot.get('endsAt')).tz(data.timeZoneName)));

    return new MeetingProposal({
      ...data,
      timeSlots: listToOrderedMap(transformedTimeSlots, timeSlot => timeSlot.get('id').toString()),
    });
  }
  constructor(props = {}) {
    super({
      ...props,
      sender: new User(props.sender),
      ...(props.recipients && { recipients: fromJS(props.recipients.map(recipient => new Recipient(recipient))) }),
    });
  }
}
