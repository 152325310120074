import color from 'color';
import initials from 'initials';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import {
  getAllUsers, getUserColors,
} from 'Modules/Proposals/selectors';

const styles = {
  root: {
    width: 32,
    height: 32,
    fontSize: '1rem',
    marginRight: -4,
  },
};

// Connect is a decorator function which
// connects this React component to our
// Redux store.
@connect(
  (state, ownProps) => {
    // Arguments: state, ownProps
    return {
      userName: getAllUsers(state).getIn([ownProps.userId, 'name'], ''),
      userColor: getUserColors(state).get(ownProps.userId),
    };
  },
  {},
)
@withStyles(styles)
export default class SchedulerFormAvatar extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    userColor: PropTypes.string.isRequired,
    userName: PropTypes.string.isRequired,
  };

  render() {
    const {
      classes, userColor, userName,
    } = this.props;
    return (
      <Avatar
        className={classes.root}
        style={{
          color: color(userColor).luminosity() > 0.5 ? 'black' : 'white',
          background: userColor,
        }}
      >{initials(userName)[0]}
      </Avatar>
    );
  }
}
