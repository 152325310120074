import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
} from '@groove-labs/groove-ui';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  INSERT_AS_PLAIN_TEXT_TOGGLE_KEY_PATH,
} from 'Modules/Proposals/constants';


const styles = {
  root: {
    'align-items': 'center',
    display: 'flex',
  },
};

const InsertAsPlainTextToggle = ({ classes }) => (
  <div className={classes.root}>
    <Switch uiKeyPath={INSERT_AS_PLAIN_TEXT_TOGGLE_KEY_PATH} />
    <Typography variant={'body2'} >
      Insert as Plain Text
    </Typography>
  </div>
);

InsertAsPlainTextToggle.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(InsertAsPlainTextToggle);
