import momentTimezone from 'moment-timezone';

import Event from 'Modules/Proposals/data/Event';

const transformEvents = ({
  events, currentUser,
}) => (
  events.map((event) => {
    const start = event.start && momentTimezone(event.start.dateTime || event.start.date).tz(currentUser.timeZoneName);
    const end = event.end && momentTimezone(event.end.dateTime || event.end.date).tz(currentUser.timeZoneName);

    // this is gcal's way of telling us that an event is all-day
    const isAllDay = event.start && !!event.start.date;

    const title = (event.visibility === 'private' && event.userId !== currentUser.id) ? 'busy' : event.summary;

    // events that span more than a day should show up at the top with the all day events
    const isGreaterThan24Hours = start && end && momentTimezone.duration(end.diff(start)).asHours() > 24;
    return (
      new Event({
        id: event.id,
        iCalUID: event.iCalUID,
        title,
        userId: event.userId,
        ...(event.transparency && { transparency: event.transparency }),
        ...(event.start && { start }),
        ...(event.end && { end }),
        isAllDay,
        isGreaterThan24Hours,
      })
    );
  })
);

export default transformEvents;
