import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Tooltip,
} from '@groove-labs/groove-ui';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import withStyles from '@material-ui/core/styles/withStyles';

import {
  DOUBLE_BOOKABLE_TOGGLE_KEY_PATH,
} from 'Modules/Proposals/constants';

const TOOLTIP_MESSAGE = 'With this setting enabled, your recipients will be able to book you at any time that you insert, even if you have another meeting on your calendar';

const styles = {
  root: {
    'align-items': 'center',
    display: 'flex',
  },
  tooltip: {
    marginLeft: 4,
  },
};

const DoubleBookingToggle = ({ classes }) => (
  <div className={classes.root}>
    <Switch uiKeyPath={DOUBLE_BOOKABLE_TOGGLE_KEY_PATH} />
    <Typography variant={'body2'} >
      Enable Double Booking
    </Typography>
    <div className={classes.tooltip}>
      <Tooltip
        title={TOOLTIP_MESSAGE}
      >
        <InfoIcon />
      </Tooltip>
    </div>
  </div>
);

DoubleBookingToggle.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(DoubleBookingToggle);
