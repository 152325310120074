import React from 'react';
import PropTypes from 'prop-types';
import momentTimezone from 'moment-timezone';
import withStyles from '@material-ui/core/styles/withStyles';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  connect,
} from 'react-redux';
import {
  Typography,
} from '@material-ui/core';

import TimeSlotGroup from 'Modules/Shared/emailComponents/TimeSlotGroup';
import CreatedTimeSlot from 'Modules/Proposals/data/CreatedTimeSlot';
import {
  getCreatedMeetingProposalId,
  getCreatedMeetingProposalTimeZoneName,
  getCreatedMeetingProposalSender,
  getOrganizationBrand,
  getTimeSlotGroupTableRows,
} from 'Modules/Proposals/selectors';
import User from 'Modules/Proposals/data/User';
import OrganizationBrand from 'Modules/Shared/data/OrganizationBrand';

const styles = theme => ({
  root: {
    width: 500,
    userSelect: 'none',
    border: '1px solid rgba(0,0,0,0.19)',
    borderRadius: 2,
    position: 'relative',
  },

  banner: {
    height: 8,
    width: 500,
    backgroundColor: theme.palette.primary.light,
  },

  image: {
    paddingBottom: 8,
  },

  header: {
    padding: 16,
  },

  meetingWithTypography: {
    paddingBottom: 8,
  },

  tableCell: {
    verticalAlign: 'top',
  },
});

const mapStateToProps = state => ({
  organizationBrand: getOrganizationBrand(state),
  proposalId: getCreatedMeetingProposalId(state),
  sender: getCreatedMeetingProposalSender(state),
  timeSlotGroupTableRows: getTimeSlotGroupTableRows(state),
  timeZoneName: getCreatedMeetingProposalTimeZoneName(state),
});

const TimeSlotGroups = ({
  classes,
  organizationBrand,
  proposalId,
  timeSlotGroupTableRows,
  timeZoneName,
  sender,
}) => (
  <div
    id="groove-scheduler-time-slots"
    className={classes.root}
    contentEditable={false}
    spellCheck={false}
    data-meeting-proposal-id={proposalId}
  >
    <div
      className={classes.banner}
      style={{
        backgroundColor: (organizationBrand.get('useCustomBranding') && organizationBrand.get('hexColor')) || '#29D5D5',
      }}
    />
    <div className={classes.header}>
      {
        organizationBrand.get('useCustomBranding')
        && organizationBrand.get('logoUrl')
        && <img
          alt="logo"
          className={classes.image}
          style={{ height: 32 }}
          src={organizationBrand.get('logoUrl')}
        />
      }
      <Typography
        variant="body1"
        className={classes.meetingWithTypography}
      >
        Meeting with {sender.get('name')}
      </Typography>
      <Typography variant="caption">
        These time slots are in {momentTimezone.tz(timeZoneName).format('z')}. Click to book a meeting.
      </Typography>
    </div>
    <table>
      {
        timeSlotGroupTableRows.map((tableRow, key) => (
          // this is a false positive, the second parameter is not an array index in this case
          /* eslint-disable-next-line react/no-array-index-key */
          <tr key={key}>
            {tableRow.map(([day, timeSlots]) => (
              <td
                key={day}
                className={classes.tableCell}
              >
                <TimeSlotGroup
                  key={day}
                  timeSlots={timeSlots}
                  day={day}
                />
              </td>
            )).valueSeq()}
          </tr>
        )).valueSeq()
      }
    </table>
  </div>
);

TimeSlotGroups.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  organizationBrand: ImmutablePropTypes.recordOf(OrganizationBrand).isRequired,
  proposalId: PropTypes.number.isRequired,
  sender: ImmutablePropTypes.recordOf(User).isRequired,
  timeSlotGroupTableRows: ImmutablePropTypes.orderedMapOf(
    ImmutablePropTypes.listOf(
      PropTypes.arrayOf(
        PropTypes.oneOf([
          PropTypes.string.isRequired,
          ImmutablePropTypes.listOf(CreatedTimeSlot),
        ]),
      ),
    ),
    PropTypes.number.isRequired,
  ).isRequired,
  timeZoneName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(TimeSlotGroups));
