import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import withTheme from '@material-ui/core/styles/withTheme';
import color from 'color';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';

import OrganizationBrand from 'Modules/Shared/data/OrganizationBrand';
import CreatedTimeSlot from 'Modules/Proposals/data/CreatedTimeSlot';
import {
  getCreatedMeetingProposalTimeZoneName,
  getMeetingSlug,
  getOrganizationBrand,
} from 'Modules/Proposals/selectors';
import {
  convertTimeToTimeZone,
  generateMeetingTimeSlotURL,
} from 'Modules/Proposals/utils';

const styles = {
  root: {
    paddingTop: 2,
    paddingBottom: 2,
  },
};

@connect(state => ({
  meetingSlug: getMeetingSlug(state),
  timeZoneName: getCreatedMeetingProposalTimeZoneName(state),
  organizationBrand: getOrganizationBrand(state),
}))
@withStyles(styles)
@withTheme()
class TimeSlot extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    meetingSlug: PropTypes.string.isRequired,
    organizationBrand: ImmutablePropTypes.recordOf(OrganizationBrand).isRequired,
    theme: PropTypes.objectOf(PropTypes.any).isRequired,
    timeSlot: PropTypes.instanceOf(CreatedTimeSlot).isRequired,
    timeZoneName: PropTypes.string.isRequired,
  };

  getFormattedStartTime = () => {
    const {
      timeSlot,
      timeZoneName,
    } = this.props;

    return convertTimeToTimeZone({
      timeZoneName,
      time: timeSlot.start,
    }).format('h:mm');
  }

  getFormattedEndTime = () => {
    const {
      timeSlot,
      timeZoneName,
    } = this.props;

    return convertTimeToTimeZone({
      timeZoneName,
      time: timeSlot.end,
    }).format('h:mmA');
  }

  getFormattedTimeSlot = () => `${this.getFormattedStartTime()} - ${this.getFormattedEndTime()}`

  render() {
    const {
      timeSlot,
      classes,
      meetingSlug,
      organizationBrand,
      theme,
    } = this.props;

    const brandColor = (organizationBrand.get('useCustomBranding') && organizationBrand.get('hexColor')) || theme.palette.primary.light;

    return (
      <div className={classes.root}>
        <Button
          key={timeSlot.publicId}
          size="small"
          color="primary"
          variant="outlined"
          disableRipple
          href={generateMeetingTimeSlotURL({
            meetingSlug,
            timeSlotId: timeSlot.publicId,
          })}
          style={{
            borderColor: brandColor,
            background: color(brandColor).fade(0.9),
            color: '#4A4A4A',
          }}
          data-time-slot-start={timeSlot.start.toISOString()}
          data-time-slot-end={timeSlot.end.toISOString()}
        >
          { this.getFormattedTimeSlot() }
        </Button>
      </div>
    );
  }
}

export default TimeSlot;
