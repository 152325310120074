import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  connect,
} from 'react-redux';

import Error from 'Modules/Error/containers/Error';
import {
  FAILED_HTTP_REQUEST_ERROR_MESSAGE,
  INVALID_GOOGLE_CALENDAR_CREDENTIALS,
  UNABLE_TO_GET_EXTENSION_AUTHENTICATION_DATA,
  NO_COMPOSE_WINDOW_ID_ERROR_MESSAGE,
} from 'Modules/Error/constants/messages';
import {
  getError,
  getLocation,
} from 'Modules/Error/selectors';
import ConfusedMan from 'Modules/Error/components/ConfusedMan';
import closeWindow from 'Utils/closeWindow';

@connect(
  state => ({
    error: getError(state),
    location: getLocation(state),
  }),
)
class ModuleRoot extends Component {
  static propTypes = {
    error: PropTypes.instanceOf(Error).isRequired,
    location: PropTypes.string.isRequired,
  }

  redirect = () => { window.location.href = this.props.location; }

  openFixPage = () => {
    window.open(`${process.env.GROOVE_ENGINE_BASE_URL}/fix`);
    closeWindow();
  }

  createErrorComponent() {
    const { location } = this.props;
    let errorComponentProps = {
      location,
      recoveryButtonOnClick: this.redirect,
    };
    switch (this.props.error.message) {
      case FAILED_HTTP_REQUEST_ERROR_MESSAGE: {
        errorComponentProps = {
          ...errorComponentProps,
          icon: <ConfusedMan />,
          headlineText: 'Request Error',
          messageBodyText: 'There was a problem with your request, please try again by reloading the previous page.',
        };
        break;
      }
      case INVALID_GOOGLE_CALENDAR_CREDENTIALS: {
        errorComponentProps = {
          ...errorComponentProps,
          icon: <ConfusedMan />,
          headlineText: 'Calendar Error',
          messageBodyText: 'We were not able to connect to your Google Calendar.',
          recoveryButtonOnClick: this.openFixPage,
          recoveryButtonText: 'Fix credentials',
        };
        break;
      }

      case UNABLE_TO_GET_EXTENSION_AUTHENTICATION_DATA: {
        errorComponentProps = {
          ...errorComponentProps,
          headlineText: 'Groove Chrome Extension Error',
          messageBodyText: 'We were not able to connect with your Groove Chrome Extension.',
          recoveryButtonOnClick: closeWindow,
          recoveryButtonText: 'Close',
        };
        break;
      }

      case NO_COMPOSE_WINDOW_ID_ERROR_MESSAGE: {
        errorComponentProps = {
          ...errorComponentProps,
          messageBodyText: 'We were not able to identify a Gmail compose window',
          recoveryButtonOnClick: closeWindow,
          recoveryButtonText: 'Close',
        };
        break;
      }
      default: {
        break;
      }
    }

    return <Error {...errorComponentProps} />;
  }

  render() {
    return (
      <React.Fragment>
        { this.createErrorComponent() }
      </React.Fragment>
    );
  }
}

export default ModuleRoot;

