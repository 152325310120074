import {
  Record,
} from 'immutable';
import momentTimezone from 'moment-timezone';

const defaults = {
  start: momentTimezone(),
  end: momentTimezone(),
  publicId: null,
};

class CreatedTimeSlot extends Record(defaults) {}

export default CreatedTimeSlot;
