import Raven from 'raven-js';
import {
  all,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';

import {
  ACTION_TYPES,
} from 'Modules/Error/actions';
import {
  setIsLoading,
} from 'Modules/App/actions';

function* showReportDialog() {
  yield Raven.showReportDialog();
}

function* watchShowReportDialog() {
  yield takeEvery(ACTION_TYPES.SHOW_REPORT_DIALOG, showReportDialog);
}

export default function* root() {
  yield put(setIsLoading(false));

  yield all([
    fork(watchShowReportDialog),
  ]);
}
