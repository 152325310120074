import { OrderedMap } from 'immutable';
import {
  all,
  put,
  select,
} from 'redux-saga/effects';

import { PROPOSALS_GROUP_ID } from 'Modules/Proposals/constants';
import {
  getCurrentUser,
  getDuration,
} from 'Modules/Proposals/selectors';
import { createTimeSlotsForTimeRange } from 'Modules/Proposals/utils';
import determineTimeSlots from 'Modules/Proposals/sagas/determineTimeSlots';
import {
  setTimeSlots, addTimeSlots,
} from 'Modules/Proposals/actions';

function* handleTimeSlotCreation({ payload: {
  start, end,
} }) {
  const [
    duration,
    currentUser,
  ] = yield all([
    select(getDuration, { groupId: PROPOSALS_GROUP_ID }),
    select(getCurrentUser),
  ]);

  const timeSlots = createTimeSlotsForTimeRange({
    start,
    end,
    timeSlotDuration: duration,
    timeZoneName: currentUser.timeZoneName,
  });

  yield put(addTimeSlots({
    freeTimeSlots: timeSlots,
    overlappingTimeSlots: new OrderedMap(),
  }));

  const transformedTimeSlots = yield* determineTimeSlots();
  yield put(setTimeSlots(transformedTimeSlots));
}

export default handleTimeSlotCreation;
