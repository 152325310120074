import {
  all,
  fork,
  put,
  takeEvery,
} from 'redux-saga/effects';

import {
  setError,
} from 'Modules/Error/actions';
import logError from 'Modules/Shared/logError';
import {
  ACTION_TYPES,
  redirectToErrorPage,
} from 'Modules/Shared/actions/errors';
import history from 'Utils/history';

function* handleErrorLogging({ payload: { error } }) {
  yield logError(error);
  yield put(redirectToErrorPage({
    error,
    location: window.location.href,
  }));
}

function* handleErrorPageRedirection({ payload: {
  error, location,
} }) {
  yield put(setError({
    error,
    location,
  }));
  history.push('/error');
}

function* watchLogError() {
  yield takeEvery(ACTION_TYPES.LOG_ERROR, handleErrorLogging);
}

function* watchErrorPageRedirection() {
  yield takeEvery(ACTION_TYPES.REDIRECT_TO_ERROR_PAGE, handleErrorPageRedirection);
}

export default function* root() {
  yield all([
    fork(watchLogError),
    fork(watchErrorPageRedirection),
  ]);
}
