import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  header: {
    paddingBottom: 8,
  },
};

const Header = ({
  classes, children,
}) => (
  <div className={classes.header}>
    <Typography variant="body2">
      { children }
    </Typography>
  </div>
);

Header.propTypes = {
  children: PropTypes.string.isRequired,
  classes: PropTypes.shape({
    header: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(Header);
