import {
  createActions,
  keyMirror,
} from 'action-utils';

const ACTION_TYPES = createActions({
  SHARED: {
    ERRORS: {
      ...keyMirror([
        'LOG_ERROR',
        'REDIRECT_TO_ERROR_PAGE',
      ]),
    },
  },
}).SHARED.ERRORS;

const logError = ({ error }) => ({
  type: ACTION_TYPES.LOG_ERROR,
  payload: {
    error,
  },
});

const redirectToErrorPage = ({
  error, location,
}) => ({
  type: ACTION_TYPES.REDIRECT_TO_ERROR_PAGE,
  payload: {
    error,
    location,
  },
});

export {
  ACTION_TYPES,
  logError,
  redirectToErrorPage,
};
