import {
  all,
  call,
  fork,
  takeEvery,
} from 'redux-saga/effects';

import {
  actionTypes,
} from 'Modules/Proposals/actions';
import {
  basicEventTrackingHandler,
} from 'Modules/Shared/sagas/analytics';

function* handleTrackMeetingTimeInserted() {
  yield call(basicEventTrackingHandler, {
    featureCategory: 'Proposals',
    eventName: 'Scheduler Times Inserted',
  });
}

function* watchMeetingProposalCreated() {
  yield takeEvery(actionTypes.CREATE_MEETING_PROPOSAL.SUCCESS, handleTrackMeetingTimeInserted);
}

export default function* root() {
  yield all([
    fork(watchMeetingProposalCreated),
  ]);
}
