import React, { Component } from 'react';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

// TODO: @jaebradley figure out why important has to be used
const styles = {
  root: {
    marginRight: '4px !important',
    marginLeft: 'auto !important',
    width: '24px !important',
    height: '24px !important',
    color: 'white !important',
  },
};

@withStyles(styles)
class TimeSlotCloseButton extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
    }).isRequired,
  };

  render = () => (
    <IconButton className={this.props.classes.root} >
      <CloseIcon />
    </IconButton>
  )
}

export default TimeSlotCloseButton;
