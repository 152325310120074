import {
  listToOrderedMap,
} from '@groove-labs/groove-ui';
import {
  fromJS,
  List,
  OrderedMap,
  OrderedSet,
} from 'immutable';

import {
  actionTypes,
} from 'Modules/Proposals/actions';
import MeetingProposal from 'Modules/Shared/data/MeetingProposal';
import MeetingType from 'Modules/Proposals/data/MeetingType';
import User from 'Modules/Proposals/data/User';
import TimeRange from 'Modules/Proposals/data/TimeRange';
import {
  generateMeetingProposal,
} from 'Modules/Proposals/utils';
import OrganizationBrand from 'Modules/Shared/data/OrganizationBrand';

const initialState = fromJS({
  currentUser: new User(),
  allUsers: new OrderedMap(),
  isLoading: true,
  userEvents: new OrderedMap(),
  timeSlots: {
    free: new OrderedMap(),
    overlapping: new OrderedMap(),
  },
  meetingTypes: new List(),
  currentMeetingType: new MeetingType(),
  auth: {},
  sender: new User(),
  otherAttendeeIds: new OrderedSet(),
  previousOtherAttendeeIds: new OrderedSet(),
  // 30 minutes, in seconds
  duration: 60 * 30,
  recipients: new List(),
  isFetchingEvents: false,
  isCreatingMeetingProposal: false,
  createdMeetingProposal: new MeetingProposal(),
  meetingSlug: '',
  composeWidgetId: '',
  userColors: new OrderedMap(),
  currentTimeRange: new TimeRange(),
  organizationBrand: new OrganizationBrand(),
});

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_EXTENSION_AUTH: {
      return state.set('auth', fromJS(action.payload));
    }
    case actionTypes.SET_CURRENT_USER: {
      return state.set('currentUser', action.payload);
    }
    case actionTypes.SET_ALL_USERS: {
      return state.set('allUsers', action.payload);
    }
    case actionTypes.SET_SENDER: {
      return state.set('sender', action.payload);
    }
    case actionTypes.SET_OTHER_ATTENDEE_IDS: {
      return state
        .set('previousOtherAttendeeIds', state.get('otherAttendeeIds'))
        .set('otherAttendeeIds', fromJS(action.payload));
    }

    case actionTypes.REVERT_OTHER_ATTENDEE_IDS: {
      return state
        .set('otherAttendeeIds', state.get('previousOtherAttendeeIds'));
    }

    case actionTypes.FETCH_EVENTS.BEGIN: {
      return state.set('isFetchingEvents', true);
    }

    case actionTypes.FETCH_EVENTS.SUCCESS:
    case actionTypes.FETCH_EVENTS.FAILURE: {
      return state.set('isFetchingEvents', false);
    }

    case actionTypes.ADD_USER_EVENTS: {
      return state.mergeIn(['userEvents'], listToOrderedMap(action.payload, ({ id }) => id));
    }

    case actionTypes.SET_USER_EVENTS: {
      return state.set('userEvents', listToOrderedMap(action.payload, ({ id }) => id));
    }
    case actionTypes.SET_MEETING_TYPES: {
      const meetingTypes = new List(action.payload.map(meetingType => new MeetingType(meetingType)));
      return state.set('meetingTypes', meetingTypes);
    }
    case actionTypes.SET_CURRENT_MEETING_TYPE: {
      return state.set('currentMeetingType', new MeetingType(action.payload));
    }
    case actionTypes.SET_TIME_SLOTS: {
      return state
        .setIn(['timeSlots', 'free'], action.payload.freeTimeSlots)
        .setIn(['timeSlots', 'overlapping'], action.payload.overlappingTimeSlots);
    }

    case actionTypes.ADD_TIME_SLOTS: {
      return state
        .mergeIn(['timeSlots', 'free'], action.payload.freeTimeSlots)
        .mergeIn(['timeSlots', 'overlapping'], action.payload.overlappingTimeSlots);
    }

    case actionTypes.DELETE_TIME_SLOT: {
      return state
        .deleteIn(['timeSlots', 'free', action.payload])
        .deleteIn(['timeSlots', 'overlapping', action.payload]);
    }

    case actionTypes.DELETE_TIME_SLOTS: {
      return state
        .setIn(['timeSlots', 'free'], new OrderedMap())
        .setIn(['timeSlots', 'overlapping'], new OrderedMap());
    }

    case actionTypes.SET_RECIPIENTS: {
      const {
        recipients,
      } = action.payload;

      return state.set('recipients', fromJS(recipients));
    }

    case actionTypes.CREATE_MEETING_PROPOSAL.BEGIN: {
      return state.set('isCreatingMeetingProposal', true);
    }

    case actionTypes.CREATE_MEETING_PROPOSAL.FAILURE: {
      return state.set('isCreatingMeetingProposal', false);
    }


    case actionTypes.CREATE_MEETING_PROPOSAL.SUCCESS: {
      const {
        payload,
      } = action;

      const proposal = generateMeetingProposal(payload);
      return state
        .set('isCreatingMeetingProposal', false)
        .set('createdMeetingProposal', proposal);
    }

    case actionTypes.SET_MEETING_SLUG: {
      const {
        meetingSlug,
      } = action.payload;

      return state.set('meetingSlug', meetingSlug);
    }

    case actionTypes.SET_COMPOSE_WIDGET_ID: {
      const {
        composeWidgetId,
      } = action.payload;

      return state.set('composeWidgetId', composeWidgetId);
    }

    case actionTypes.SET_USER_COLORS: {
      return state.set('userColors', action.payload);
    }

    case actionTypes.SET_CURRENT_TIME_RANGE: {
      return state
        .set('currentTimeRange', fromJS(action.payload));
    }

    case actionTypes.SET_ORGANIZATION_BRAND: {
      return state.set('organizationBrand', new OrganizationBrand(action.payload));
    }

    case actionTypes.SET_USER_ZOOM_EMAIL: {
      return state.set('userZoomEmail', action.payload);
    }

    default:
      return state;
  }
}
