import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Map } from 'immutable';
import Fade from '@material-ui/core/Fade';

import {
  getLocation, getIsLoading,
} from 'Modules/App/selectors';
import match from 'Routing/matcher';
import Snackbar from 'Modules/App/containers/Snackbar';
import {
  logError,
} from 'Modules/Shared/actions/errors';

const styles = theme => ({
  '@global body': {
    margin: 0,
    padding: 0,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    height: '100vh',
  },
  contents: {
    width: '100%',
    height: '100vh',
  },
  loading: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    backgroundColor: 'transparent',
    zIndex: 91991,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.quaternary.main,
  },
  contentsLoading: {
    // this is necessary to override the Loader component's opacity
    opacity: '0.2 !important',
    width: '100%',
    height: '100vh',
  },
});

@connect(
  (state) => {
    return {
      location: getLocation(state),
      isLoading: getIsLoading(state),
    };
  },
  {
    logError,
  },
)
@withStyles(styles)
export default class ModuleRoot extends Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    isLoading: PropTypes.bool.isRequired,
    logError: PropTypes.func.isRequired,
    location: ImmutablePropTypes.contains({
      pathname: PropTypes.string.isRequired,
    }),
  };

  static defaultProps = {
    location: new Map(),
  };

  componentDidCatch(error) {
    this.props.logError({ error });
  }

  render() {
    const {
      classes, location, isLoading,
    } = this.props;

    const currentRoute = match(location.get('pathname'));
    const ModuleRoot = currentRoute && currentRoute.moduleRoot;

    return (
      <div>
        {
          isLoading && (
            <div className={classes.loading}>
              <CircularProgress color="inherit" />
            </div>
          )
        }

        <Fade
          timeout={750}
          in
        >
          <div className={(isLoading && classes.contentsLoading) || classes.content}>
            {!isLoading && <ModuleRoot />}
          </div>
        </Fade>
        <Snackbar />
      </div>
    );
  }
}
