import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Snackbar as MuiSnackbar } from '@material-ui/core';

import { requestSnackbarClose } from 'Modules/Shared/actions/snackbar';
import {
  getIsSnackbarOpen, getSnackbarMessage,
} from 'Modules/App/selectors';


const mapStateToProps = state => ({
  open: getIsSnackbarOpen(state),
  message: getSnackbarMessage(state),
});

const mapDispatchToProps = {
  requestSnackbarClose,
};

const Snackbar = ({
  open, message, requestSnackbarClose,
}) => (
  <MuiSnackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    open={open}
    autoHideDuration={3000}
    onClose={requestSnackbarClose}
    message={message}
    action={[
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={requestSnackbarClose}
      >
        <CloseIcon />
      </IconButton>,
    ]}
  />
);

Snackbar.propTypes = {
  message: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  requestSnackbarClose: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Snackbar);
