import {
  List,
  OrderedMap,
} from 'immutable';
import momentTimezone from 'moment-timezone';
import shortid from 'shortid';

import TimeSlot from 'Modules/Proposals/data/TimeSlot';
import CreatedTimeSlot from 'Modules/Proposals/data/CreatedTimeSlot';
import MeetingProposal from 'Modules/Shared/data/MeetingProposal';

const createTimeSlotsForTimeRange = ({
  start, end, timeSlotDuration, timeZoneName,
}) => {
  const timeSlots = [];
  const now = momentTimezone().tz(timeZoneName);

  let timeSlotStartTime = momentTimezone(start).tz(timeZoneName);
  let timeSlotEndTime = momentTimezone(timeSlotStartTime).add(timeSlotDuration, 'seconds');

  // TODO: @jaebradley Track ineligible time slot count and report via Snackbar
  while (timeSlotStartTime.isBefore(now)) {
    timeSlotStartTime = timeSlotEndTime;
    timeSlotEndTime = momentTimezone(timeSlotStartTime).add(timeSlotDuration, 'seconds');
  }

  while (timeSlotStartTime.isBefore(end) && timeSlotEndTime.isSameOrBefore(end)) {
    const timeSlot = new TimeSlot({
      id: shortid.generate(),
      start: timeSlotStartTime,
      end: timeSlotEndTime,
    });
    timeSlots.push([timeSlot.id, timeSlot]);

    timeSlotStartTime = timeSlotEndTime;
    timeSlotEndTime = momentTimezone(timeSlotStartTime).tz(timeZoneName).add(timeSlotDuration, 'seconds');
  }

  return new OrderedMap(timeSlots);
};

const convertTimeToTimeZone = ({
  time, timeZoneName, keepLocalTimeValue = false,
}) => momentTimezone(time.clone()).tz(timeZoneName, keepLocalTimeValue);
const roundToNearestMinute = (time) => {
  if (time.seconds() === 59) {
    return time.add(1, 'second');
  }
  return time;
};

const formatTimeSlot = ({
  timeSlot, timeZoneName,
}) => ({
  starts_at: Math.round(convertTimeToTimeZone({
    timeZoneName,
    time: timeSlot.start,
  }).valueOf() / 1000),
  ends_at: Math.round(convertTimeToTimeZone({
    timeZoneName,
    time: timeSlot.end,
  }).valueOf() / 1000),
});

const generateCreatedTimeSlots = ({
  timeSlots, timeZoneName,
}) => new List(timeSlots.map(({
  publicId, startsAt, endsAt,
}) => new CreatedTimeSlot({
  publicId,
  start: momentTimezone(startsAt).tz(timeZoneName),
  end: momentTimezone(endsAt).tz(timeZoneName),
})));

const generateMeetingProposal = ({
  id,
  title,
  status,
  location,
  description,
  timeZoneName,
  sender,
  timeSlots,
}) => (
  new MeetingProposal({
    id,
    title,
    status,
    location,
    description,
    timeZoneName,
    sender,
    timeSlots: generateCreatedTimeSlots({
      timeSlots,
      timeZoneName,
    }),
  })
);

const generateMeetingTimeSlotURL = ({
  meetingSlug, timeSlotId,
}) => `${process.env.SCHEDULER_BASE_URL}/${meetingSlug}/${timeSlotId}`;

// TODO: @jaebradley replace selector function with call to this helper method

const getDailyTimeSlotGroups = timeSlots => timeSlots
  .sort((slot1, slot2) => slot1.start.diff(slot2.start))
  .groupBy(timeSlots => timeSlots.start.format('dddd, MMM DD'));

const getDailyPlainTextTimeSlotGroups = timeSlots => timeSlots
  .sort((slot1, slot2) => slot1.start.diff(slot2.start))
  .groupBy(timeSlots => timeSlots.start.format('dddd, MMMM Do YYYY'));

export {
  convertTimeToTimeZone,
  roundToNearestMinute,
  createTimeSlotsForTimeRange,
  formatTimeSlot,
  generateMeetingProposal,
  generateMeetingTimeSlotURL,
  getDailyTimeSlotGroups,
  getDailyPlainTextTimeSlotGroups,
};
