import {
  createActions,
  keyMirror,
} from 'action-utils';

const ACTION_TYPES = createActions({
  ERROR: {
    ...keyMirror([
      'SET_ERROR',
      'SHOW_REPORT_DIALOG',
    ]),
  },
}).ERROR;

const triggerShowReportDialog = () => ({
  type: ACTION_TYPES.SHOW_REPORT_DIALOG,
});

const setError = ({
  error, location,
}) => ({
  type: ACTION_TYPES.SET_ERROR,
  payload: {
    error,
    location,
  },
});

export {
  ACTION_TYPES,
  triggerShowReportDialog,
  setError,
};
