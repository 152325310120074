import {
  all,
  select,
} from 'redux-saga/effects';

import {
  getAllTimeSlots,
  getUserEvents,
} from 'Modules/Proposals/selectors';
import identifyTimeSlots from 'Modules/Proposals/utils/identifyTimeSlots';

export default function* determineTimeSlots() {
  const [
    timeSlots,
    userEvents,
  ] = yield all([
    select(getAllTimeSlots),
    select(getUserEvents),
  ]);

  return identifyTimeSlots({
    timeSlots,
    userEvents,
  });
}
