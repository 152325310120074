import {
  createActions,
  keyMirror,
  standardActions,
} from 'action-utils';

export const actionTypes = createActions({
  APP: {
    ...keyMirror([
      'APPLY_MEETING_TYPE',
      'ADD_TIME_SLOTS',
      'ADD_USER_EVENTS',
      'CREATE_TIME_SLOTS',
      'DELETE_TIME_SLOT',
      'DELETE_TIME_SLOTS',
      'SET_ALL_USERS',
      'SET_COMPOSE_WIDGET_ID',
      'SET_CURRENT_TIME_RANGE',
      'SET_CURRENT_MEETING_TYPE',
      'SET_CURRENT_USER',
      'SET_EXTENSION_AUTH',
      'SET_MEETING_SLUG',
      'SET_MEETING_TYPES',
      'SET_OTHER_ATTENDEE_IDS',
      'REVERT_OTHER_ATTENDEE_IDS',
      'SET_RECIPIENTS',
      'SET_SENDER',
      'SET_ORGANIZATION_BRAND',
      'SET_TIME_SLOTS',
      'SET_USER_COLORS',
      'SET_USER_EVENTS',
      'SET_USER_ZOOM_EMAIL',
      'UPDATE_SENDER',
    ]),
    CREATE_MEETING_PROPOSAL: standardActions(),
    FETCH_EVENTS: standardActions(),
  },
}).APP;

export const setExtensionAuth = ({
  grooveEngineRootUrl,
  grooveEngineUserEmail,
  grooveEngineUserId,
  grooveExtensionToken,
  sfdcUserId,
  userDigest,
}) => ({
  type: actionTypes.SET_EXTENSION_AUTH,
  payload: {
    grooveEngineRootUrl,
    grooveEngineUserEmail,
    grooveEngineUserId,
    grooveExtensionToken,
    sfdcUserId,
    userDigest,
  },
});

export const setCurrentUser = payload => ({
  type: actionTypes.SET_CURRENT_USER,
  payload,
});

export const setAllUsers = payload => ({
  type: actionTypes.SET_ALL_USERS,
  payload,
});

export const setSender = payload => ({
  type: actionTypes.SET_SENDER,
  payload,
});

export const setOtherAttendeeIds = payload => ({
  type: actionTypes.SET_OTHER_ATTENDEE_IDS,
  payload,
});

export const revertOtherAttendeeIds = () => ({
  type: actionTypes.REVERT_OTHER_ATTENDEE_IDS,
});

export const addUserEvents = payload => ({
  type: actionTypes.ADD_USER_EVENTS,
  payload,
});

export const setUserEvents = payload => ({
  type: actionTypes.SET_USER_EVENTS,
  payload,
});

export const setMeetingTypes = payload => ({
  type: actionTypes.SET_MEETING_TYPES,
  payload,
});

export const setRecipients = recipients => ({
  type: actionTypes.SET_RECIPIENTS,
  payload: {
    recipients,
  },
});

export const applyMeetingType = payload => ({
  type: actionTypes.APPLY_MEETING_TYPE,
  payload,
});

export const setCurrentMeetingType = payload => ({
  type: actionTypes.SET_CURRENT_MEETING_TYPE,
  payload,
});

export const createTimeSlots = ({
  start,
  end,
}) => ({
  type: actionTypes.CREATE_TIME_SLOTS,
  payload: {
    start,
    end,
  },
});

export const deleteTimeSlot = id => ({
  type: actionTypes.DELETE_TIME_SLOT,
  payload: id,
});

export const deleteTimeSlots = () => ({
  type: actionTypes.DELETE_TIME_SLOTS,
});

export const beginCreatingMeetingProposal = payload => ({
  type: actionTypes.CREATE_MEETING_PROPOSAL.BEGIN,
  payload,
});

export const successCreatingMeetingProposal = payload => ({
  type: actionTypes.CREATE_MEETING_PROPOSAL.SUCCESS,
  payload,
});

export const setMeetingSlug = meetingSlug => ({
  type: actionTypes.SET_MEETING_SLUG,
  payload: {
    meetingSlug,
  },
});

export const setComposeWidgetId = composeWidgetId => ({
  type: actionTypes.SET_COMPOSE_WIDGET_ID,
  payload: {
    composeWidgetId,
  },
});

export const setUserColors = payload => ({
  type: actionTypes.SET_USER_COLORS,
  payload,
});

export const setCurrentTimeRange = payload => ({
  type: actionTypes.SET_CURRENT_TIME_RANGE,
  payload,
});

export const addTimeSlots = ({
  freeTimeSlots,
  overlappingTimeSlots,
}) => ({
  type: actionTypes.ADD_TIME_SLOTS,
  payload: {
    freeTimeSlots,
    overlappingTimeSlots,
  },
});

export const setTimeSlots = ({
  freeTimeSlots,
  overlappingTimeSlots,
}) => ({
  type: actionTypes.SET_TIME_SLOTS,
  payload: {
    freeTimeSlots,
    overlappingTimeSlots,
  },
});

export const updateSender = ({ sender }) => ({
  type: actionTypes.UPDATE_SENDER,
  payload: {
    sender,
  },
});

export const setOrganizationBrand = organizationBrand => ({
  type: actionTypes.SET_ORGANIZATION_BRAND,
  payload: organizationBrand,
});

export const setUserZoomEmail = currentZoomEmail => ({
  type: actionTypes.SET_USER_ZOOM_EMAIL,
  payload: currentZoomEmail,
});
