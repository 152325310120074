import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import {
  PROPOSALS_GROUP_ID,
} from 'Modules/Proposals/constants';
import Calendar from 'Modules/Proposals/containers/Calendar';
import SchedulerForm from 'Modules/Proposals/containers/SchedulerForm';
import SubmitButtons from 'Modules/Proposals/containers/SubmitButtons';

const styles = {
  root: {
    display: 'flex',
  },
  rightPane: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
};

const ModuleRoot = ({ classes }) => (
  <div className={classes.root}>
    <div className={classes.root}>
      <SchedulerForm groupId={PROPOSALS_GROUP_ID} />
      <div className={classes.rightPane}>
        <Calendar />
        <SubmitButtons />
      </div>
    </div>
  </div>
);

ModuleRoot.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default withStyles(styles)(ModuleRoot);
