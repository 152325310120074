import {
  Record,
} from 'immutable';
import momentTimezone from 'moment-timezone';

const defaults = {
  start: momentTimezone().startOf('isoWeek'),
  end: momentTimezone().endOf('isoWeek'),
};

export default class TimeRange extends Record(defaults) {}
