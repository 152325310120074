import {
  all,
  fork,
} from 'redux-saga/effects';

import errors from './errors';
import snackbar from './snackbar';

function* root() {
  yield all([
    fork(errors),
    fork(snackbar),
  ]);
}

export default root;
