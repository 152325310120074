import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const TimeSlotHeader = ({
  caption, headline,
}) => (
  <div>
    <Typography variant="caption">
      { caption }
    </Typography>
    <Typography variant="headline">
      { headline }
    </Typography>
  </div>
);

TimeSlotHeader.propTypes = {
  caption: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
};

export default TimeSlotHeader;
