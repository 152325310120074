import {
  RIT, Input, Select, formGroupConnect, Picklist, formInputValidators,
} from '@groove-labs/groove-ui';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {
  List, Map, OrderedSet, OrderedMap,
} from 'immutable';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import * as cx from 'classnames';

import {
  getCurrentUser,
  getAllUsers,
  getOtherAttendeeIds,
  getEnabledMeetingTypes,
  getSender,
  getIsValidTitle,
  getUserZoomEmail,
  getVideoConferencing,
} from 'Modules/Proposals/selectors';
import {
  TIME_ZONES,
  DURATION_OPTIONS,
  PROPOSALS_GROUP_ID,
} from 'Modules/Proposals/constants';
import {
  applyMeetingType,
  setOtherAttendeeIds,
  updateSender,
} from 'Modules/Proposals/actions';
import DoubleBookingToggle from 'Modules/Proposals/components/SchedulerForm/DoubleBookingToggle';
import SchedulerFormAvatar from 'Modules/Proposals/containers/SchedulerFormAvatar';
import User from 'Modules/Proposals/data/User';
import InsertAsPlainTextToggle from 'Modules/Proposals/components/SchedulerForm/InsertAsPlainTextToggle';
import SelectableButtonGroup from 'Modules/Proposals/components/SelectableButtonGroup';
import SelectableButton from 'Modules/Proposals/components/SelectableButtonGroup/SelectableButton';
import * as zoomLogo from 'Modules/Proposals/images/zoomLogo.png';
import GoogleMeetIcon from 'Modules/Proposals/components/GoogleMeetIcon';
import { getLDClient } from 'Utils/ldClient';

import LocationInput from './LocationInput';

const {
  PresenceValidator,
} = formInputValidators;

const styles = {
  root: {
    height: '100vh',
    overflowY: 'scroll',
    padding: '16px 10px',
    width: 286,
    flexShrink: 0,
    borderRight: '1px solid rgba(0,0,0,0.19)',
  },
  applySavedMeetingButton: {
    marginTop: 12,
  },
  title: {
    paddingBottom: 16,
  },
  senderCaption: {
    paddingBottom: 4,
  },
  picklistInput: {
    width: 280,
  },
  picklistDropdown: {
    width: 280,
    zIndex: 999,
  },
  requiredInput: {
    color: 'red',
  },
  attendeeChip: {
    marginBottom: 5,
  },
  labelTitle: {
    paddingTop: 16,
    paddingBottom: 6,
    fontSize: 14,
  },
  selectableButton: {
    margin: '4px 0 14px',
    background: 'rgba(167, 167, 167, 0.3)',
    borderRadius: '8px',
    height: '29px',
    width: '122px',
  },
  zoomButton: {
    marginLeft: '12px',
  },
  disabledButton: {
    display: 'none',
  },
  selectedButton: {
    border: '2px solid #29D5D5',
    background: 'white',
  },
  iconText: {
    paddingLeft: 8,
    color: '#6e6e6e',
    fontSize: '9px',
  },
  iconTextSelected: {
    color: '#303030',
  },
  zoomLogo: {
    height: '11px',
  },
};

@formGroupConnect()
@connect(
  (state) => {
    return {
      currentUser: getCurrentUser(state),
      userZoomEmail: getUserZoomEmail(state),
      allUsers: getAllUsers(state),
      otherAttendeeIds: getOtherAttendeeIds(state),
      sender: getSender(state),
      meetingTypes: getEnabledMeetingTypes(state),
      isTitleValid: getIsValidTitle(state, { groupId: PROPOSALS_GROUP_ID }),
      videoConferencing: getVideoConferencing(state, { groupId: PROPOSALS_GROUP_ID }),
    };
  },
  {
    applyMeetingType,
    setOtherAttendeeIds,
    updateSender,
  },
)
@withStyles(styles)
export default class SchedulerForm extends Component {
  static propTypes = {
    allUsers: PropTypes.instanceOf(OrderedMap).isRequired,
    applyMeetingType: PropTypes.func.isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    currentUser: PropTypes.instanceOf(User).isRequired,
    groupId: PropTypes.string.isRequired,
    isTitleValid: PropTypes.bool.isRequired,
    meetingTypes: PropTypes.instanceOf(List).isRequired,
    otherAttendeeIds: PropTypes.instanceOf(OrderedSet).isRequired,
    sender: PropTypes.instanceOf(User).isRequired,
    setOtherAttendeeIds: PropTypes.func.isRequired,
    updateSender: PropTypes.func.isRequired,
    userZoomEmail: PropTypes.string.isRequired,
    videoConferencing: PropTypes.string,
  };

  static defaultProps = {
    videoConferencing: '',
  };

  constructor() {
    super();

    this.state = {
      anchorEl: null,
    };
  }

  getOtherAttendeeOptions() {
    const {
      allUsers,
      otherAttendeeIds,
      sender,
    } = this.props;
    return allUsers
      .filterNot(user => otherAttendeeIds.has(user.get('id')))
      .delete(sender.get('id'))
      .valueSeq()
      .toList();
  }

  getSenderPicklistOptionLabel = user => user.get('name');

  getOtherAttendeesPicklistOptionLabel = user => user.get('name');

  getTimeZonePicklistOptionLabel = timezone => timezone.get('label');

  getRequiredSymbol = () => <span className={this.props.classes.requiredInput}> *</span>

  getTitleLabel = () => <div>Title{ this.props.isTitleValid || this.getRequiredSymbol() }</div>

  getSenderLabel = () => <div>Schedule for:{ !!this.props.sender.id || this.getRequiredSymbol() }</div>

  handleSenderOnChange = sender => this.props.updateSender({ sender });

  handleSenderOnDelete = () => this.props.updateSender({ sender: new User() });

  handleOtherAttendeesPicklistOnChange = (option) => {
    const {
      otherAttendeeIds, setOtherAttendeeIds,
    } = this.props;

    setOtherAttendeeIds(otherAttendeeIds.add(option.get('id')));
  }
  handleOtherAttendeesDelete = otherAttendeeId => () => {
    const {
      otherAttendeeIds, setOtherAttendeeIds,
    } = this.props;

    setOtherAttendeeIds(otherAttendeeIds.delete(otherAttendeeId));
  }

  handleMeetingTypeMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMeetingTypeMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSelectMeetingType = meetingType => () => {
    const {
      applyMeetingType,
    } = this.props;

    applyMeetingType(meetingType);

    this.handleMeetingTypeMenuClose();
  };

  render() {
    const {
      classes,
      groupId,
      currentUser,
      userZoomEmail,
      allUsers,
      otherAttendeeIds,
      meetingTypes,
      sender,
      videoConferencing,
    } = this.props;

    const { anchorEl } = this.state;

    const defaultTimeZone = TIME_ZONES.find(timeZone => timeZone.get('value') === currentUser.get('timeZoneName')) || new Map();

    const videoConferencingLinkEnabled = getLDClient().variation('scheduler-video-conferencing');
    const zoomEnabled = getLDClient().variation('zoom');
    const isZoomConnected = !!userZoomEmail;
    const showZoomButton = zoomEnabled && isZoomConnected;

    return (
      <div className={classes.root}>
        <Typography
          variant="subheading"
          className={classes.title}
        >
          Meeting Details
        </Typography>

        <Typography
          variant="caption"
          className={classes.senderCaption}
        >
          { this.getSenderLabel() }
        </Typography>
        {RIT(!sender.get('id'), () => (
          <Picklist
            classes={{
              inputRoot: classes.picklistInput,
              paper: classes.picklistDropdown,
            }}
            groupId={groupId}
            fieldId="sender"
            getOptionLabel={this.getSenderPicklistOptionLabel}
            options={allUsers.valueSeq().toList()}
            onChange={this.handleSenderOnChange}
            blurInputOnSelect
            clearInputOnSelect
          />
        ))}
        {RIT(sender.get('id'), () => (
          <div>
            <Chip
              avatar={<SchedulerFormAvatar userId={sender.get('id')} />}
              label={allUsers.getIn([sender.get('id'), 'name'])}
              onDelete={this.handleSenderOnDelete}
            />
          </div>
        ))}

        <Button
          className={classes.applySavedMeetingButton}
          color="primary"
          size="small"
          aria-owns={anchorEl ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={this.handleMeetingTypeMenuOpen}
        >
          Apply saved meeting
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMeetingTypeMenuClose}
        >
          {meetingTypes.map(meetingType => (
            <MenuItem
              key={meetingType.get('id')}
              onClick={this.handleSelectMeetingType(meetingType)}
            >
              {meetingType.get('name')}
            </MenuItem>
          ))}
        </Menu>

        <Input
          groupId={groupId}
          fieldId="title"
          label={this.getTitleLabel()}
          validators={[new PresenceValidator()]}
        />

        <Select
          groupId={groupId}
          fieldId="duration"
          initialValue={1800}
          options={DURATION_OPTIONS}
          placeholder="Duration"
          validateBeforeRegistration
        />

        {videoConferencingLinkEnabled && (
          <React.Fragment>
            <Typography
              variant="caption"
              classes={{ root: classes.labelTitle }}
            >
              Video conferencing
            </Typography>
            <SelectableButtonGroup
              groupId={groupId}
              fieldId="videoConferencing"
            >
              <SelectableButton
                value="hangouts"
                className={classes.selectableButton}
                selectedClassName={classes.selectedButton}
              >
                <GoogleMeetIcon />
                <Typography
                  className={cx({
                    [classes.iconText]: true,
                    [classes.iconTextSelected]: videoConferencing === 'hangouts',
                  })}
                  variant="body2"
                >
                  Google Meet
                </Typography>
              </SelectableButton>
              <SelectableButton
                value="zoom"
                className={cx({
                  [classes.selectableButton]: showZoomButton,
                  [classes.zoomButton]: showZoomButton,
                  [classes.disabledButton]: !showZoomButton,
                })}
                selectedClassName={classes.selectedButton}
              >
                <img
                  className={classes.zoomLogo}
                  src={zoomLogo}
                  alt="Zoom logo"
                />
              </SelectableButton>
            </SelectableButtonGroup>
            <LocationInput groupId={groupId} />
          </React.Fragment>
        )}

        {!videoConferencingLinkEnabled && (
          <Input
            groupId={groupId}
            fieldId="location"
            label="Location"
          />
        )}

        <Picklist
          classes={{
            inputRoot: classes.picklistInput,
            paper: classes.picklistDropdown,
          }}
          groupId={groupId}
          fieldId="other-attendees"
          getOptionLabel={this.getOtherAttendeesPicklistOptionLabel}
          options={this.getOtherAttendeeOptions()}
          onChange={this.handleOtherAttendeesPicklistOnChange}
          blurInputOnSelect
          clearInputOnSelect
          label="Other Attendees"
          validateBeforeRegistration
        />

        {otherAttendeeIds.map(otherAttendeeId => (
          <Chip
            className={classes.attendeeChip}
            key={otherAttendeeId}
            avatar={<SchedulerFormAvatar userId={otherAttendeeId} />}
            label={allUsers.getIn([otherAttendeeId, 'name'])}
            onDelete={this.handleOtherAttendeesDelete(otherAttendeeId)}
          />
        ))}

        <Input
          muiProps={{
            multiline: true,
            rows: 5,
            rowsMax: 20,
          }}
          groupId={groupId}
          fieldId="description"
          label="Description"
          validateBeforeRegistration
        />

        <Picklist
          classes={{
            inputRoot: classes.picklistInput,
            paper: classes.picklistDropdown,
          }}
          groupId={groupId}
          fieldId="timezone"
          initialValue={defaultTimeZone}
          getOptionLabel={this.getTimeZonePicklistOptionLabel}
          options={TIME_ZONES}
          label="Timezone (displayed in invitation)"
        />

        <DoubleBookingToggle />
        <InsertAsPlainTextToggle />
      </div>
    );
  }
}
