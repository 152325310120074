import { MuiThemeProvider } from '@material-ui/core';
import React from 'react';
import { renderToString } from 'react-dom/server';
import juice from 'juice';
import declassify from 'declassify';
import {
  Provider,
} from 'react-redux';

import grooveTheme from 'Utils/grooveTheme';
import store from 'Redux/store';
import TimeSlotGroups from 'Modules/Shared/emailComponents/TimeSlotGroups';
import TimeSlotsPlainText from 'Modules/Shared/emailComponents/TimeSlotsPlainText';

let hasGeneratedHtmlAtLeastOnce = false;

/* eslint-disable react/no-danger */
const getHtmlForPlainTextGroups = () => renderToString(
  <html lang="en">
    <body>
      <Provider store={store}>
        <TimeSlotsPlainText />
      </Provider>
    </body>
  </html>,
);

const getHtmlForGroups = () => renderToString(
  <html lang="en">
    <head dangerouslySetInnerHTML={{ __html: document.head.innerHTML }} />
    <body>
      <MuiThemeProvider theme={grooveTheme}>
        <Provider store={store}>
          <div>
            <TimeSlotGroups />
          </div>
        </Provider>
      </MuiThemeProvider>
    </body>
  </html>,
);
/* eslint-enable react/no-danger */

const generateTimeSlotsHTML = (plainText) => {
  let html;
  if (plainText) {
    html = getHtmlForPlainTextGroups();
  } else {
    html = getHtmlForGroups();

    // this is a hack to ensure that the jss gets properly injected the first time
    if (!hasGeneratedHtmlAtLeastOnce) {
      hasGeneratedHtmlAtLeastOnce = true;
      html = getHtmlForGroups();
    }
  }

  const declassifyOptions = {
    ignore: [
      'ignored-class',
      /ignored-regex-class[0-9]+/,
      'ignored-id',
      /ignored-regex-id[0-9]+/,
    ],
    attrs: ['id', 'class'],
  };

  if (plainText) {
    return html;
  }

  return declassify.process(juice(html, {
    preserveMediaQueries: false,
    preserveFontFaces: false,
    preserveKeyFrames: false,
    preservePseudos: false,
  }), declassifyOptions);
};

export default generateTimeSlotsHTML;
