import Raven from 'raven-js';

const logError = (error) => {
  // captureMessage (and by extension captureException)
  // call down to _makeRequest
  // https://github.com/getsentry/sentry-javascript/blob/master/packages/raven-js/src/raven.js#L2179

  if (!!error && !!error.stack && !!error.sagaStack) {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.error('stack trace is', error.stack);
      // eslint-disable-next-line no-console
      console.error('saga stack is', error.sagaStack);
    }

    return Raven.captureException(error.stack, {
      extra: {
        sagaStack: error.sagaStack,
      },
    });
  }

  return Raven.captureMessage('Unable to identify error', {
    extra: {
      errorInformation: error,
    },
  });
};

export default logError;
