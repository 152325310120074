import { sagas as grooveUiSagas } from '@groove-labs/groove-ui';
import {
  fork, take, cancel,
} from 'redux-saga/effects';

import routes from 'Routing/saga';
import SharedSagas from 'Modules/Shared/sagas';

const sagas = [
  grooveUiSagas.form,
  routes,
  SharedSagas,
];

export const CANCEL_SAGAS_HMR = 'CANCEL_SAGAS_HMR';

function createAbortableSaga(saga) {
  if (process.env.NODE_ENV === 'development') {
    return function* main() {
      const sagaTask = yield fork(saga);

      yield take(CANCEL_SAGAS_HMR);
      yield cancel(sagaTask);
    };
  }
  return saga;
}

const SagaManager = {
  startSagas(sagaMiddleware) {
    sagas.map(createAbortableSaga).forEach(saga => sagaMiddleware.run(saga));
  },

  cancelSagas(store) {
    store.dispatch({
      type: CANCEL_SAGAS_HMR,
    });
  },
};

export default SagaManager;
