import {
  Record,
} from 'immutable';
import momentTimezone from 'moment-timezone';

const defaults = {
  id: null,
  iCalUID: '',
  start: momentTimezone(),
  end: momentTimezone(),
  title: '',
  userId: null,
  isAllDay: false,
  transparency: 'opaque',
  isGreaterThan24Hours: false,
};

class Event extends Record(defaults) {
}

export default Event;
