import red from '@material-ui/core/colors/red';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const GROOVEY_BLUEY = '#29D5D5';
const GROOVEY_BLUEY_1 = '#B8ECED';
const GROOVEY_BLUEY_2 = '#E4F8F9';

const TANGERINE_DREAM = '#FBC122';
const WAVY_NAVY = '#04294C';
const WAVY_NAVY_1 = '#577693';
const WAVY_NAVY_2 = '#C6DAEC';
const WAVY_NAVY_3 = '#E1ECF7';
const SURREAL_TEAL = '#0960B2';

const GRAY_1 = '#757575';
const GRAY_3 = '#CACACA';

const BLACK = '#303030';

export default createMuiTheme({
  palette: {
    primary: {
      main: WAVY_NAVY,
      light: GROOVEY_BLUEY,
    },
    secondary: {
      main: TANGERINE_DREAM,
    },
    error: red,
    text: {
      primary: BLACK,
      secondary: GRAY_1,
      disabled: GRAY_1,
      hint: GRAY_1,
    },
    tertiary: {
      main: GROOVEY_BLUEY,
      light: GROOVEY_BLUEY_1,
      lighter: GROOVEY_BLUEY_2,
    },
    quaternary: {
      main: SURREAL_TEAL,
      dark: WAVY_NAVY_1,
      light: WAVY_NAVY_2,
      lighter: WAVY_NAVY_3,
    },
  },
  shape: {
    borderRadius: 2,
  },
  groove: {
    unit: 4,
  },
  'Connect(SearchableDropdownMenu)': {
    menuItem: {
      height: 24,
    },
  },
  typography: {
    button: {
      textTransform: 'uppercase',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        padding: 0,
        width: 48,
        height: 48,
        '&:hover': {
          backgroundColor: 'inherit',
        },
      },
    },
    MuiInput: {
      root: {
        lineHeight: 'normal',
      },
      underline: {
        '&:after': {
          borderBottom: `2px solid ${WAVY_NAVY}`,
        },
      },
    },
    MuiSwitch: {
      switchBase: {
        width: 'auto',
        height: 'auto',
      },
    },
    MuiTable: {
      root: {
        overflow: 'hidden',
      },
    },
    MuiAvatar: {
      root: {
        width: 40,
        height: 40,
      },
    },
    MuiButton: {
      root: {
        height: 36,
        minWidth: 88,
        minHeight: 36,
      },
      text: {
        fontSize: 12,
        fontWeight: 600,
        padding: '8px 16px',
        letterSpacing: 0.5,
      },
      containedSizeSmall: {
        padding: '7px 8px',
      },
    },
    MuiCardHeader: {
      root: {
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiCardContent: {
      root: {
        paddingLeft: 16,
        paddingRight: 16,
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiChip: {
      avatar: {
        width: 32,
        color: GRAY_3,
        height: 32,
        fontSize: '1rem',
        marginLeft: 0,
        marginRight: '-4px',
      },
      root: {
        '& $avatar': {
          color: undefined,
          height: 32,
          width: 32,
          marginLeft: undefined,
          marginRight: undefined,
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 0,
        width: 48,
        height: 48,
      },
    },
    MuiFab: {
      root: {
        fontFamily: 'inherit',
      },
    },
    MuiFormHelperText: {
      root: {
        fontFamily: 'inherit',
        marginTop: 8,
        minHeight: '1em',
      },
    },
    MuiFormControlLabel: {
      root: {
        marginLeft: -14,
      },
    },
    MuiRadio: {
      root: {
        padding: 0,
      },
    },
    MuiTab: {
      wrapper: {
        fontSize: '0.8125rem',
        letterSpacing: '1.5px',
      },
      root: {
        fontFamily: 'inherit',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: 'normal',
        '@media (min-width: 960px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        marginLeft: 'auto',
        minHeight: '1em',
      },
    },
    MuiInputBase: {
      root: {
        fontFamily: 'inherit',
      },
      input: {
        width: '100%',
      },
      fullWidth: {
        '& $input': {
          width: '100%',
        },
      },
    },
    MuiTableRow: {
      root: {
        height: 48,
        '&$selected': {
          '&$root': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        fontFamily: 'inherit',
        padding: '4px 56px 4px 24px',
        fontSize: '0.75rem',
        letterSpacing: 'normal',
        lineHeight: 'inherit',
      },
      paddingCheckbox: {
        padding: 'inherit',
      },
    },
    MuiList: {
      padding: {
        paddingTop: 4,
        paddingBottom: 4,
      },
    },
    MuiListItem: {
      root: {
        paddingTop: 8,
        paddingBottom: 8,
        '@media (min-width: 600px)': {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      dense: {
        paddingTop: 8,
        paddingBottom: 8,
      },
    },
    MuiListItemText: {
      root: {
        padding: '0 16px',
        marginTop: 0,
        marginBottom: 0,
        '&:first-child': {
          paddingLeft: 0,
        },
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: 'inherit',
        paddingTop: 12,
        paddingBottom: 12,
        height: 24,
        boxSizing: 'content-box',
      },
    },
    MuiSelect: {
      select: {
        width: '100%',
      },
    },
    MuiTypography: {
      root: {
        display: 'block',
        fontFamily: 'inherit',
      },
      body1: {
        fontSize: 13,
        fontFamily: 'inherit',
        fontWeight: 400,
        letterSpacing: '0.1px',
        lineHeight: '1.46429em',
      },
      body2: {
        fontSize: 13,
        fontFamily: 'inherit',
        fontWeight: 600,
        letterSpacing: '0.2px',
      },
      caption: {
        fontFamily: 'inherit',
        letterSpacing: 'normal',
      },
      subtitle1: {
        fontWeight: 600,
        fontFamily: 'inherit',
        letterSpacing: 'normal',
      },
      h1: {
        fontFamily: 'inherit',
      },
      h2: {
        fontFamily: 'inherit',
      },
      h3: {
        fontSize: 18,
        fontWeight: 600,
        fontFamily: 'inherit',
      },
      h4: {
        fontFamily: 'inherit',
      },
      h5: {
        fontFamily: 'inherit',
      },
      h6: {
        fontSize: '1.3125rem',
        fontFamily: 'inherit',
        fontWeight: 600,
        lineHeight: '1.16667em',
        letterSpacing: '0.5px',
      },
      subtitle2: {
        fontFamily: 'inherit',
      },
      button: {
        fontFamily: 'inherit',
      },
      overline: {
        fontFamily: 'inherit',
      },
    },
  },
  props: {
    MuiFab: {
      color: 'secondary',
    },
    MuiSwitch: {
      color: 'primary',
    },
    MuiCheckbox: {
      color: 'primary',
    },
    MuiTypography: {
      color: 'textPrimary',
      variant: 'body1',
    },
    MuiListItemText: {
      primaryTypographyProps: {
        variant: 'subtitle1',
      },
      secondaryTypographyProps: {
        variant: 'body1',
      },
    },
  },
});
