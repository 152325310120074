import { GrooveHTTPClient } from '@groove-labs/groove-http-client';
import {
  select,
  call,
} from 'redux-saga/effects';

import { getRecipients } from 'Modules/Proposals/selectors';

export default function* getMeetingTypes() {
  const recipients = yield select(getRecipients);
  const emailAddress = recipients.getIn([0, 'emailAddress'], '');
  const options = {
    path: '/api/v2/scheduler/meeting_types',
    queryParameters: {
      merge_field_email_address: emailAddress,
      request_merge_fields: true,
    },
  };
  return yield call(GrooveHTTPClient.makeRequest, options);
}
