import React, { Component } from 'react';
import * as cx from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

const styles = {
  root: {
    display: 'flex',
    placeContent: 'center',
  },
};

@withStyles(styles)
export default class SelectableButton extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
    }).isRequired,
    onClick: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,

    className: PropTypes.string,
    isSelected: PropTypes.bool,
    selectedClassName: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    selectedClassName: '',
    isSelected: false,
  }

  handleToggleClick = (event) => {
    event.preventDefault();

    const {
      onClick, isSelected, value,
    } = this.props;
    if (isSelected) {
      onClick(null);
    } else {
      onClick(value);
    }
  };

  render() {
    const {
      className,
      classes,
      children,
      isSelected,
      selectedClassName,
    } = this.props;

    return (
      <ButtonBase
        className={cx({
          [classes.root]: true,
          [className]: !!className,
          [selectedClassName]: isSelected,
        })}
        onClick={this.handleToggleClick}
      >
        {children}
      </ButtonBase>
    );
  }
}
