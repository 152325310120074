const FAILED_HTTP_REQUEST_ERROR_MESSAGE = 'Failed HTTP request';
const INVALID_GOOGLE_CALENDAR_CREDENTIALS = 'Invalid Google Calendar credentials';
const NO_COMPOSE_WINDOW_ID_ERROR_MESSAGE = 'No compose window id found';
const UNABLE_TO_GET_EXTENSION_AUTHENTICATION_DATA = 'Unable to get extension authentication data';

export {
  FAILED_HTTP_REQUEST_ERROR_MESSAGE,
  NO_COMPOSE_WINDOW_ID_ERROR_MESSAGE,
  INVALID_GOOGLE_CALENDAR_CREDENTIALS,
  UNABLE_TO_GET_EXTENSION_AUTHENTICATION_DATA,
};
