/* eslint-disable react/sort-comp */
import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import FullCalendar from 'fullcalendar-reactwrapper';
import momentTimezone from 'moment-timezone';
import pluralize from 'pluralize';
import PropTypes from 'prop-types';
import {
  renderToString,
} from 'react-dom/server';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  connect,
} from 'react-redux';

import {
  createTimeSlots,
  deleteTimeSlot,
  setCurrentTimeRange,
} from 'Modules/Proposals/actions';
import Header from 'Modules/Proposals/containers/Calendar/Header';
import Event from 'Modules/Proposals/data/Event';
import TimeSlot from 'Modules/Proposals/data/TimeSlot';
import TimeRange from 'Modules/Proposals/data/TimeRange';
import TimeSlotCloseButton from 'Modules/Proposals/containers/Calendar/TimeSlotCloseButton';
import TimeSlotHeader from 'Modules/Proposals/containers/Calendar/TimeSlotHeader';
import {
  PROPOSALS_GROUP_ID,
  PRIMARY_EVENT_COLOR,
} from 'Modules/Proposals/constants';
import {
  getUnavailableTimeSlots,
  getCurrentTimeRange,
  getCurrentUser,
  getDuration,
  getIsFetchingEvents,
  getUserColors,
  getUserEvents,
  getAvailableTimeSlots,
} from 'Modules/Proposals/selectors';
import {
  convertTimeToTimeZone,
  roundToNearestMinute,
} from 'Modules/Proposals/utils';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
  },
  event: {
    backgroundColor: PRIMARY_EVENT_COLOR,
    border: '1px solid #FFFFFF',
  },
  pastEvent: {
    opacity: 0.5,
  },
  header: {
    paddingBottom: 8,
  },
  timeSlot: {
    background: theme.palette.primary.main,
    color: 'white',
  },
});

@connect(
  (state) => {
    const currentUser = getCurrentUser(state);
    return {
      unavailableTimeSlots: getUnavailableTimeSlots(state),
      currentTimeRange: getCurrentTimeRange(state),
      duration: Number(getDuration(state, { groupId: PROPOSALS_GROUP_ID })),
      isFetchingEvents: getIsFetchingEvents(state),
      userColors: getUserColors(state),
      userEvents: getUserEvents(state),
      userTimeZoneName: currentUser.timeZoneName,
      availableTimeSlots: getAvailableTimeSlots(state),
    };
  },
  {
    createTimeSlots,
    deleteTimeSlot,
    setCurrentTimeRange,
  },
)
@withStyles(styles)
export default class Calendar extends Component {
  static propTypes = {
    availableTimeSlots: ImmutablePropTypes.listOf(TimeSlot).isRequired,
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    createTimeSlots: PropTypes.func.isRequired,
    currentTimeRange: ImmutablePropTypes.recordOf(TimeRange).isRequired,
    deleteTimeSlot: PropTypes.func.isRequired,
    duration: PropTypes.number.isRequired,
    isFetchingEvents: PropTypes.bool.isRequired,
    setCurrentTimeRange: PropTypes.func.isRequired,
    unavailableTimeSlots: ImmutablePropTypes.orderedMapOf(TimeSlot).isRequired,
    userColors: ImmutablePropTypes.orderedMapOf(PropTypes.string).isRequired,
    userEvents: ImmutablePropTypes.listOf(Event).isRequired,
    userTimeZoneName: PropTypes.string.isRequired,
  };

  static header = {
    left: 'today prev,next title',
    center: '',
    right: '',
  }

  static getHeaderElement(date) {
    const formattedDate = date.format('YYYY-MM-DD');
    return $(`.fc-day-header[data-date='${formattedDate}'`);
  }

  static BOUNDARY_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

  static replaceHeader(date) {
    const headerElement = Calendar.getHeaderElement(date);
    const replacementHeaderHTML = renderToString(
      <TimeSlotHeader
        caption={date.format('ddd')}
        headline={date.format('DD')}
      />,
    );
    headerElement.html(replacementHeaderHTML);
  }

  static isCalendarItemATimeSlot(calendarItem) {
    return !calendarItem.iCalUID;
  }

  static shouldShowEventInAllDaySlot = event => event.isAllDay || event.isGreaterThan24Hours;

  constructor() {
    super();
    this.state = {
      hasFullCalendarRendered: true,
    };
  }

  // Fullcalendar returns slots in UTC (even is specify a timezonename prop)
  // Thus, we need to convert the selected time slots to a zone-aware time, using the "local" time slot values picked in the calendar
  // So if the current user is in PST and picks a time slot that starts at 1PM, Fullcalendar will set the slot time at 1PM UTC
  // Need to convert the 1PM value to PST by essentially replacing the timezone value
  onSelectSlot = (start, end) => {
    const {
      createTimeSlots,
      userTimeZoneName,
    } = this.props;


    createTimeSlots({
      start: roundToNearestMinute(convertTimeToTimeZone({
        time: start,
        keepLocalTimeValue: true,
        timeZoneName: userTimeZoneName,
      })),
      end: roundToNearestMinute(convertTimeToTimeZone({
        time: end,
        keepLocalTimeValue: true,
        timeZoneName: userTimeZoneName,
      })),
    });
  }

  getTransformedUserEvents = () => this.props.userEvents.toJS().map((userEvent) => {
    userEvent.allDay = this.constructor.shouldShowEventInAllDaySlot(userEvent);

    // From the Full Calendar Documentation (https://fullcalendar.io/docs/event-object)
    // The exclusive date/time an event ends.
    // It is the moment immediately after the event has ended.
    // For example, if the last full day of an event is Thursday,
    // the exclusive end of the event will be 00:00:00 on Friday!
    // Because of this, in the case where the start is equal to the end we need to increment the end by the smallest moment of time that is supported,
    // which is a millisecond
    if (userEvent.start.isSame(userEvent.end)) {
      userEvent.end = userEvent.end.add({ milliseconds: 1 });
    }
    return userEvent;
  });

  getViews = () => ({
    agendaWeek: {
      allDaySlot: this.props.userEvents.some(this.constructor.shouldShowEventInAllDaySlot),
      allDayText: '',
      displayEventEnd: true,
      displayEventTime: true,
      nowIndicator: true,
      scrollTime: '08:00:00',
    },
  })


  getFormattedDuration = () => momentTimezone()
    .tz(this.props.userTimeZoneName)
    .startOf('day')
    .seconds(this.props.duration)
    .format('HH:mm:ss');

  getDateTimeBoundaries = () => ({
    start: momentTimezone().tz(this.props.userTimeZoneName).format(Calendar.BOUNDARY_DATE_TIME_FORMAT),
    end: momentTimezone().add(1000, 'year').tz(this.props.userTimeZoneName).format(Calendar.BOUNDARY_DATE_TIME_FORMAT),
  })

  replaceSelectedTimeSlotsText = () => {
    $('.fc-right').html(renderToString(
      <Typography variant="body2">
        { `${this.props.availableTimeSlots.size} ${pluralize('time slots', this.props.availableTimeSlots.size)} selected` }
      </Typography>,
    ));
  }

  resizeScrollableContainer = () => {
    const allDaySlot = $('.fc-day-grid.fc-unselectable');
    const timeGridContainer = $('.fc-time-grid-container');
    if (allDaySlot) {
      timeGridContainer.css('max-height', `calc(100vh - 240px - ${allDaySlot.height()}px)`);
    }
  }

  appendTimeSlotCloseButton = ({
    element, timeSlot,
  }) => {
    const {
      classes,
      deleteTimeSlot,
    } = this.props;

    const calendarItemContent = element.find('.fc-content');
    if (calendarItemContent) {
      element.addClass(classes.timeSlot);
      const closeButton = $(renderToString(<TimeSlotCloseButton />));
      closeButton.click(() => deleteTimeSlot(timeSlot.id));

      calendarItemContent.css('display', 'flex');
      calendarItemContent.append(closeButton);
    }
  }

  eventRender = (event, element) => {
    const {
      classes,
      userColors,
      unavailableTimeSlots,
    } = this.props;

    const fcContent = element.find('.fc-content');

    element.css('background-color', userColors.get(event.userId));

    if (event.transparency === 'transparent') {
      element.css('background-color', '#EEE');
      element.css('border', `1px solid ${userColors.get(event.userId)}`);
    }

    if (Calendar.isCalendarItemATimeSlot(event)) {
      element.find('.fc-time').css('color', 'white');
      this.appendTimeSlotCloseButton({
        element,
        timeSlot: event,
      });
      if (unavailableTimeSlots.has(event.id)) {
        element.css('background-color', 'red');
      }
    }

    const fcTitle = fcContent.find('.fc-title');
    fcTitle.insertBefore(fcTitle.prev());
    element.addClass(classes.event);
    if (event.end && event.end.isBefore(momentTimezone().tz(this.props.userTimeZoneName))) {
      element.addClass(classes.pastEvent);
    }

    return element;
  }

  eventAfterRender = (event, $el) => {
    const formattedTime = `${momentTimezone(event.start).tz(this.props.userTimeZoneName).format('h:mm')} — ${momentTimezone(event.end).tz(this.props.userTimeZoneName).format('h:mmA')}`;
    $el.find('.fc-time').text(formattedTime);
  }

  eventAfterAllRender = (view) => {
    view.timeGrid.dayDates.forEach(date => Calendar.replaceHeader(date));
    this.replaceSelectedTimeSlotsText();
    this.resizeScrollableContainer();
  }

  viewRender = () => {
    const {
      currentTimeRange, setCurrentTimeRange, isFetchingEvents,
    } = this.props;
    const { hasFullCalendarRendered } = this.state;

    // assign click handlers after the first render
    if (hasFullCalendarRendered) {
      $('body').on('click', 'button.fc-prev-button', () => {
        setCurrentTimeRange({
          start: currentTimeRange.get('start').subtract(1, 'week'),
          end: currentTimeRange.get('end').subtract(1, 'week'),
        });
      });
      $('body').on('click', 'button.fc-next-button', () => {
        setCurrentTimeRange({
          start: currentTimeRange.get('start').add(1, 'week'),
          end: currentTimeRange.get('end').add(1, 'week'),
        });
      });

      this.setState({ hasFullCalendarRendered: false });
    }

    $('.fc-view-container').css({
      transition: 'opacity 0.3s',
      opacity: 1,
      ...(isFetchingEvents && {
        opacity: 0.4,
        pointerEvents: 'none',
      }),
    });
  }

  render() {
    const {
      availableTimeSlots,
      unavailableTimeSlots,
      classes,
      currentTimeRange,
    } = this.props;


    // TODO: @jaebradley find a better way to combine calendar items so that a new object isn't created
    // This object will cause rerenders
    const allCalendarItems = this.getTransformedUserEvents().concat((availableTimeSlots.concat(unavailableTimeSlots)).toJS());

    return (
      <div className={classes.root}>
        <Header>
          Choose Available Times
        </Header>
        <FullCalendar
          id="scheduler-calendar"
          events={allCalendarItems}
          defaultDate={currentTimeRange.get('start')}
          header={this.constructor.header}
          views={this.getViews()}
          titleFormat="MMMM YYYY"
          defaultView="agendaWeek"
          firstDay={1}
          selectable
          selectHelper
          columnHeaderHtml={this.columnHeaderHtml}
          select={this.onSelectSlot}
          eventRender={this.eventRender}
          eventAfterRender={this.eventAfterRender}
          eventAfterAllRender={this.eventAfterAllRender}
          selectConstraint={this.getDateTimeBoundaries()}
          viewRender={this.viewRender}
          snapDuration={this.getFormattedDuration()}
        />
      </div>
    );
  }
}
