import * as Proposals from 'Modules/Proposals';
import * as ErrorModule from 'Modules/Error';

import Route from './Route';

const routes = {
  '/': new Route(Proposals),
  '/proposals/new': new Route(Proposals),
  '/error': new Route(ErrorModule),
};

export default routes;
