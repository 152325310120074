import {
  createActions, keyMirror, standardActions,
} from 'action-utils';

export const actionTypes = createActions({
  APP: {
    ...keyMirror([
      'SET_LOCATION',
      'SET_IS_LOADING',
    ]),
    CREATE_MEETING_PROPOSAL: standardActions(),
  },
}).APP;

export const setLocation = location => ({
  type: actionTypes.SET_LOCATION,
  payload: location,
});

export const setIsLoading = isLoading => ({
  type: actionTypes.SET_IS_LOADING,
  payload: isLoading,
});
