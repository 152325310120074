/* eslint max-len: 0 */

import React from 'react';

const ConfusedMan = () => (
  <svg
    width="146"
    height="146"
    viewBox="0 0 146 146"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73 146C113.317 146 146 113.317 146 73C146 32.6832 113.317 0 73 0C32.6832 0 0 32.6832 0 73C0 113.317 32.6832 146 73 146Z"
      fill="#03A3BB"
      fillOpacity="0.25"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="146"
      height="146"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73 146C113.317 146 146 113.317 146 73C146 32.6832 113.317 0 73 0C32.6832 0 0 32.6832 0 73C0 113.317 32.6832 146 73 146Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        d="M92.828 45.2718L87.4364 40.5024C87.4364 40.5024 73.8225 44.3646 72.9204 38.1025C72.9204 38.1025 70.7534 38.6508 70.2177 37.4695C69.682 36.2881 73.8881 31.5864 73.8881 31.5864C73.8881 31.5864 74.3789 26.509 78.5574 24.4374C82.736 22.3659 93.1287 19.4413 94.2969 20.6598C95.4651 21.8784 99.5675 35.8379 99.5675 35.8379C99.5675 35.8379 100.428 41.8564 92.828 45.2718Z"
        fill="#F19F4D"
      />
      <path
        d="M104.169 77.9536C104.169 77.9536 93.2956 79.0258 59.1437 82.5623C18.6922 86.7488 1.55339 106.337 -6.91747 131.157C-9.5712 138.945 -7.00582 147.495 1.21021 147.549L87.5937 148.264L96.2344 119.077L131.042 108.366C145.582 103.89 150.801 85.966 140.944 74.3593L104.692 31.6904L84.2773 45.084L104.169 77.9536Z"
        fill="#016776"
      />
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="-8"
        y="31"
        width="154"
        height="118"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M104.104 78.0671C104.104 78.0671 93.2389 79.1366 59.1123 82.6644C18.6906 86.8406 1.56449 106.381 -6.90012 131.14C-9.55189 138.908 -6.9884 147.437 1.22155 147.492L38.2309 147.787L87.5412 148.205L96.1755 119.09L130.958 108.405C145.486 103.94 150.702 86.0597 140.852 74.4816L104.627 31.9175L84.2273 45.2782L104.104 78.0671Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          d="M-3.52882 130.851C2.27316 130.851 6.97639 126.148 6.97639 120.346C6.97639 114.544 2.27316 109.841 -3.52882 109.841C-9.33079 109.841 -14.034 114.544 -14.034 120.346C-14.034 126.148 -9.33079 130.851 -3.52882 130.851Z"
          fill="#3361C2"
        />
        <path
          d="M41.7923 130.851C47.5943 130.851 52.2975 126.148 52.2975 120.346C52.2975 114.544 47.5943 109.841 41.7923 109.841C35.9907 109.841 31.2871 114.544 31.2871 120.346C31.2871 126.148 35.9907 130.851 41.7923 130.851Z"
          fill="#EE6352"
        />
        <path
          d="M87.4529 130.851C93.2549 130.851 97.9581 126.148 97.9581 120.346C97.9581 114.544 93.2549 109.841 87.4529 109.841C81.651 109.841 76.9477 114.544 76.9477 120.346C76.9477 126.148 81.651 130.851 87.4529 130.851Z"
          fill="#EE6352"
        />
        <path
          d="M19.7666 104.959C25.5686 104.959 30.2718 100.255 30.2718 94.4535C30.2718 88.6515 25.5686 83.9482 19.7666 83.9482C13.965 83.9482 9.26141 88.6515 9.26141 94.4535C9.26141 100.255 13.965 104.959 19.7666 104.959Z"
          fill="#EE6352"
        />
        <path
          d="M65.0911 104.959C70.8928 104.959 75.5964 100.255 75.5964 94.4535C75.5964 88.6515 70.8928 83.9482 65.0911 83.9482C59.2892 83.9482 54.5859 88.6515 54.5859 94.4535C54.5859 100.255 59.2892 104.959 65.0911 104.959Z"
          fill="#EE6352"
        />
        <path
          d="M110.412 104.959C116.214 104.959 120.917 100.255 120.917 94.4535C120.917 88.6515 116.214 83.9482 110.412 83.9482C104.61 83.9482 99.907 88.6515 99.907 94.4535C99.907 100.255 104.61 104.959 110.412 104.959Z"
          fill="#EE6352"
        />
        <path
          d="M87.4529 77.2326C93.2549 77.2326 97.9581 72.5294 97.9581 66.7274C97.9581 60.9254 93.2549 56.2222 87.4529 56.2222C81.651 56.2222 76.9477 60.9254 76.9477 66.7274C76.9477 72.5294 81.651 77.2326 87.4529 77.2326Z"
          fill="#EE6352"
        />
        <path
          d="M133.032 77.2326C138.834 77.2326 143.537 72.5294 143.537 66.7274C143.537 60.9254 138.834 56.2222 133.032 56.2222C127.23 56.2222 122.527 60.9254 122.527 66.7274C122.527 72.5294 127.23 77.2326 133.032 77.2326Z"
          fill="#EE6352"
        />
        <path
          d="M110.412 52.3615C116.214 52.3615 120.917 47.6583 120.917 41.8563C120.917 36.0543 116.214 31.3511 110.412 31.3511C104.61 31.3511 99.907 36.0543 99.907 41.8563C99.907 47.6583 104.61 52.3615 110.412 52.3615Z"
          fill="#EE6352"
        />
        <path
          d="M19.7666 159.45C25.5686 159.45 30.2718 154.747 30.2718 148.945C30.2718 143.143 25.5686 138.44 19.7666 138.44C13.965 138.44 9.26141 143.143 9.26141 148.945C9.26141 154.747 13.965 159.45 19.7666 159.45Z"
          fill="#3361C2"
        />
      </g>
      <path
        d="M62.8916 96.2022H62.5594C61.1424 96.1623 59.7469 95.8364 58.4525 95.2433C57.158 94.6498 55.9904 93.8003 55.0158 92.7438C54.0412 91.6871 53.2792 90.4435 52.7733 89.0847C52.2671 87.7255 52.0269 86.2774 52.0661 84.8232L53.1025 45.8658C53.1809 42.9273 54.3937 40.1415 56.4737 38.1206C58.5538 36.0998 61.3307 35.0096 64.1937 35.0901C67.057 35.1705 69.7718 36.4152 71.741 38.5495C73.7101 40.6842 74.772 43.534 74.6936 46.4726L73.6539 85.43C73.5772 88.3092 72.4103 91.0445 70.401 93.0554C68.392 95.0667 65.6984 96.1954 62.8916 96.2022Z"
        fill="#F19F4D"
      />
      <path
        d="M70.4707 74.6506L72.5561 74.6945C74.9813 74.7451 77.3884 74.2693 79.6054 73.3004C81.8225 72.3315 83.7954 70.8935 85.3834 69.089C86.9714 67.2845 88.1355 65.1578 88.793 62.8602C89.4505 60.5624 89.5853 58.1504 89.1874 55.7959L87.2221 44.1395C86.5866 40.3722 84.6223 36.9458 81.6743 34.4624C78.726 31.9785 74.9827 30.5969 71.1018 30.5595C68.9341 30.5375 66.7836 30.9361 64.7727 31.7322C62.7618 32.5279 60.9302 33.7061 59.382 35.1987C57.8338 36.6914 56.5994 38.4692 55.7498 40.4313C54.8999 42.3934 54.4512 44.5009 54.4293 46.6334L54.3264 58.2493C54.2828 62.5231 55.9532 66.642 58.9752 69.712C61.9976 72.7823 66.1278 74.5568 70.4707 74.6506Z"
        fill="#F19F4D"
      />
      <path
        d="M50.665 62.109C52.5868 64.7601 56.2247 65.3008 58.7906 63.3165C61.3569 61.3321 61.879 57.5745 59.9576 54.9231L57.986 52.2029C56.0646 49.5518 52.4264 49.0111 49.8605 50.9951C47.2945 52.9794 46.772 56.7374 48.6934 59.3885L50.665 62.109Z"
        fill="#F19F4D"
      />
      <path
        d="M50.9303 55.4585L55.4574 59.9856"
        stroke="#002329"
        strokeWidth="0.754522"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M80.2755 48.667L81.4884 58.3957L78.093 58.853"
        stroke="#002329"
        strokeWidth="0.754522"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.469 58.8534L63.4587 41.5668L64.1656 37.4642C65.2796 38.4432 66.5489 39.234 67.923 39.8048C72.9115 41.9046 87.1633 39.6008 88.2152 29.4796C89.0877 21.2242 80.8085 26.671 76.3003 26.5673C69.2217 26.4002 63.9762 22.7623 54.8616 28.7541C43.9478 35.9295 50.7761 50.4876 50.7761 50.4876C50.7761 50.4876 55.6665 48.1003 60.469 58.8534Z"
        fill="#6B3E29"
      />
      <path
        d="M73.8752 74.6987L67.907 74.7018L73.8752 79.2057V74.6987Z"
        fill="#24272D"
      />
      <path
        d="M75.2636 54.326C76.2009 54.326 76.9612 53.566 76.9612 52.6283C76.9612 51.6907 76.2009 50.9307 75.2636 50.9307C74.3259 50.9307 73.5659 51.6907 73.5659 52.6283C73.5659 53.566 74.3259 54.326 75.2636 54.326Z"
        fill="#002329"
      />
      <path
        d="M85.4497 54.326C86.3873 54.326 87.1473 53.566 87.1473 52.6283C87.1473 51.6907 86.3873 50.9307 85.4497 50.9307C84.512 50.9307 83.752 51.6907 83.752 52.6283C83.752 53.566 84.512 54.326 85.4497 54.326Z"
        fill="#002329"
      />
      <path
        d="M71.3024 61.1244C71.4509 61.1244 76.2117 60.7836 76.9613 64.5116L71.3024 61.1244Z"
        fill="#002329"
      />
    </g>
  </svg>

);

export default ConfusedMan;
