import {
  createActions,
  keyMirror,
} from 'action-utils';

export const actionTypes = createActions({
  SHARED: {
    SNACKBAR: {
      ...keyMirror([
        'PUSH_SNACKBAR_MESSAGE',
        'SHIFT_SNACKBAR_MESSAGE',
        'SET_SNACKBAR_OPEN',
        'SET_SNACKBAR_CLOSED',
        'REQUEST_SNACKBAR_CLOSE',
      ]),
    },
  },
}).SHARED.SNACKBAR;

export const pushSnackbarMessage = ({ message }) => ({
  type: actionTypes.PUSH_SNACKBAR_MESSAGE,
  payload: { message },
});

export const shiftSnackbarMessage = () => ({
  type: actionTypes.SHIFT_SNACKBAR_MESSAGE,
});

export const setSnackbarOpen = () => ({
  type: actionTypes.SET_SNACKBAR_OPEN,
});

export const setSnackbarClosed = () => ({
  type: actionTypes.SET_SNACKBAR_CLOSED,
});

export const requestSnackbarClose = () => ({
  type: actionTypes.REQUEST_SNACKBAR_CLOSE,
});

