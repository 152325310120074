import {
  actions,
} from '@groove-labs/groove-ui';
import {
  put,
  select,
} from 'redux-saga/effects';

import {
  deleteTimeSlots,
  setCurrentMeetingType,
} from 'Modules/Proposals/actions';
import {
  PROPOSALS_GROUP_ID,
} from 'Modules/Proposals/constants';
import {
  getCurrentMeetingType,
  getDuration,
} from 'Modules/Proposals/selectors';
import { getLDClient } from 'Utils/ldClient';

const { updateFieldValue } = actions.form;

function* handleApplyMeetingType({ payload: meetingType }) {
  const currentMeetingType = yield select(getCurrentMeetingType);

  if (currentMeetingType.get('id') === meetingType.get('id')) {
    return null;
  }

  const currentDuration = yield select(getDuration, { groupId: PROPOSALS_GROUP_ID });

  yield put(updateFieldValue({
    groupId: PROPOSALS_GROUP_ID,
    fieldId: 'title',
    value: meetingType.get('subject'),
    isValid: !!meetingType.get('subject'),
  }));

  yield put(updateFieldValue({
    groupId: PROPOSALS_GROUP_ID,
    fieldId: 'duration',
    value: meetingType.get('duration'),
    isValid: true,
  }));

  yield put(updateFieldValue({
    groupId: PROPOSALS_GROUP_ID,
    fieldId: 'location',
    value: meetingType.get('location'),
    isValid: true,
  }));

  yield put(updateFieldValue({
    groupId: PROPOSALS_GROUP_ID,
    fieldId: 'description',
    value: meetingType.get('description'),
    isValid: true,
  }));

  const videoConferencingLinkEnabled = getLDClient().variation('scheduler-video-conferencing');
  if (videoConferencingLinkEnabled) {
    yield put(updateFieldValue({
      groupId: PROPOSALS_GROUP_ID,
      fieldId: 'videoConferencing',
      value: meetingType.get('videoConferencing'),
      isValid: true,
    }));
  }

  yield put(setCurrentMeetingType(meetingType));

  if (currentDuration !== meetingType.get('duration')) {
    yield put(deleteTimeSlots());
  }

  return null;
}

export default handleApplyMeetingType;
