import React, { Component } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  selectors,
  actions,
} from '@groove-labs/groove-ui';

// @bmosigisi TODO On monorepo port over use:
// https://github.com/GrooveLabs/febes/blob/master/src/js/modules/Shared/components/SelectableButtonGroup/index.tsx
const { makeGetFieldValue } = selectors.form;

const {
  requestRegisterField,
  requestDeleteField,
  updateFieldValue,
} = actions.form;

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
};

@connect(
  (state, {
    fieldId,
    groupId,
  }) => ({
    fieldValue: makeGetFieldValue(fieldId)(state, { groupId }),
  }),
  {
    requestRegisterField,
    requestDeleteField,
    updateFieldValue,
  },
)
@withStyles(styles)
export default class SelectableButtonGroup extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    classes: PropTypes.shape({
      root: PropTypes.string.isRequired,
    }).isRequired,
    fieldId: PropTypes.string.isRequired,
    groupId: PropTypes.string.isRequired,
    requestDeleteField: PropTypes.func.isRequired,
    requestRegisterField: PropTypes.func.isRequired,
    updateFieldValue: PropTypes.func.isRequired,

    fieldValue: PropTypes.string,
    initialValue: PropTypes.string,
  };

  static defaultProps = {
    initialValue: null,
    fieldValue: null,
  };

  componentDidMount() {
    const {
      groupId,
      fieldId,
      initialValue,
      requestRegisterField,
    } = this.props;
    requestRegisterField({
      groupId,
      fieldId,
      type: 'text',
      initialValue,
      value: initialValue || '',
      isValid: true,
    });
  }

  componentWillUnmount() {
    const {
      fieldId, groupId, requestDeleteField,
    } = this.props;
    requestDeleteField({
      fieldId,
      groupId,
    });
  }

  handleChildClick = (childValue) => {
    const {
      groupId,
      fieldId,
      fieldValue,
      updateFieldValue,
    } = this.props;
    if (childValue !== fieldValue) {
      updateFieldValue({
        groupId,
        fieldId,
        value: childValue,
      });
    }
  };

  render() {
    const {
      classes, fieldValue, children,
    } = this.props;

    return (
      <div className={classes.root}>
        {React.Children.map(children, (child) => {
          return React.cloneElement(child, {
            onClick: this.handleChildClick,
            isSelected: child.props.value === fieldValue,
          });
        })}
      </div>
    );
  }
}
