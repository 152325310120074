import React from 'react';

const GoogleMeetIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 27 22"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#00832D"
          d="M15.537 10.935l2.542 2.824 3.417 2.123.596-4.928-.596-4.82-3.483 1.865-2.476 2.936z"
        />
        <path
          fill="#0066DA"
          d="M.79 15.425v4.2c0 .228.046.454.136.665.09.21.221.402.387.564a1.831 1.831 0 001.264.509h4.32l.894-3.175-.894-2.763-2.964-.87-3.143.87z"
        />
        <path
          fill="#E94235"
          d="M6.897.507L.79 6.445l3.143.87 2.964-.87.88-2.727-.88-3.21z"
        />
        <path
          fill="#2684FC"
          d="M6.897 6.445H.79v8.98h6.107v-8.98z"
        />
        <path
          fill="#00AC47"
          // eslint-disable-next-line max-len
          d="M25.399 3.022l-3.902 3.112v9.748l3.918 3.124c.586.445 1.444.04 1.444-.685V3.693c0-.733-.878-1.136-1.46-.671zM15.538 10.935v4.49h-8.64v5.937h12.81a1.83 1.83 0 001.265-.508c.166-.162.297-.354.387-.564.09-.211.136-.437.136-.665v-3.743l-5.958-4.947z"
        />
        <path
          fill="#FFBA00"
          d="M19.709.507H6.897v5.938h8.64v4.49l5.959-4.801V2.245c0-.228-.046-.454-.136-.665a1.735 1.735 0 00-.387-.564 1.792 1.792 0 00-.58-.376 1.832 1.832 0 00-.684-.133z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H26.069V20.855H0z"
            transform="translate(.79 .507)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleMeetIcon;
