import randomcolor from 'randomcolor';
import {
  UserConfigs as UserConfigsClient,
  Scheduler as SchedulerClient,
} from '@groove-labs/groove-http-client';
import {
  OrderedMap,
} from 'immutable';
import {
  all,
  call,
  put,
  select,
} from 'redux-saga/effects';

import {
  setUserColors,
  setOtherAttendeeIds,
  setSender,
  setMeetingTypes,
  setMeetingSlug,
} from 'Modules/Proposals/actions';
import {
  getUserColors,
  getOtherAttendeeIds,
  getSender,
} from 'Modules/Proposals/selectors';
import {
  PRIMARY_EVENT_COLOR,
} from 'Modules/Proposals/constants';
import { FailedRequestError } from 'Modules/Proposals/errors';

function* fetchMeetingSlug(userId) {
  let userConfigsResponse = null;
  try {
    userConfigsResponse = yield call(UserConfigsClient.search, { userIds: [userId] });
  } catch (e) {
    throw new FailedRequestError();
  }
  if (userConfigsResponse.data && userConfigsResponse.data.length > 0) {
    return userConfigsResponse.data[0].meetingIdentifier;
  }

  throw new Error(`Unable to identify a user configuration for ${userId}`);
}

function* fetchMeetingTypes(userId) {
  let meetingTypesResponse = null;
  try {
    meetingTypesResponse = yield* SchedulerClient.getMeetingTypes(userId);
  } catch (e) {
    throw new FailedRequestError();
  }
  return meetingTypesResponse.data;
}

function* handleUpdateSender({ payload: { sender } }) {
  const [
    userColors,
    otherAttendeeIds,
    currentSender,
  ] = yield all([
    select(getUserColors),
    select(getOtherAttendeeIds),
    select(getSender),
  ]);

  if (sender === currentSender) {
    return;
  }

  yield put(setSender(sender));
  yield put(setOtherAttendeeIds(otherAttendeeIds.delete(sender.id)));

  let updatedUserColors = new OrderedMap(userColors.entrySeq().map(([userId, colorHex]) => {
    if (colorHex === PRIMARY_EVENT_COLOR) {
      return [
        userId,
        randomcolor({ luminosity: 'light' }),
      ];
    }
    return [userId, colorHex];
  }));

  updatedUserColors = updatedUserColors.set(sender.id, PRIMARY_EVENT_COLOR);

  yield put(setUserColors(updatedUserColors));

  if (sender.id) {
    const [
      meetingTypes,
      meetingSlug,
    ] = yield all([
      call(fetchMeetingTypes, sender.id),
      call(fetchMeetingSlug, sender.id),
    ]);

    yield put(setMeetingTypes(meetingTypes));
    yield put(setMeetingSlug(meetingSlug));
  }
}

export default handleUpdateSender;
