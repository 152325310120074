import {
  gql,
  GraphQLClient,
} from 'graphql-request';
import {
  apply,
} from 'redux-saga/effects';

import logError from 'Modules/Shared/logError';

export function getQuery() {
  return gql`
    query getZoomConnection {
      viewer {
        zoomConnection {
          email
        }
      }
    }
  `;
}

function* handleFetchUserZoomEmail(url, token) {
  let email = '';
  try {
    const client = new GraphQLClient(`${url}/api/graphql`,
      {
        headers: {
          grooveAuthorization: token,
        },
        credentials: 'include',
      },
    );
    const query = getQuery();
    const currentZoomConnectionResponse = yield apply(client, client.request, [query]);
    const zoomConnection = currentZoomConnectionResponse.viewer.zoomConnection;
    if (zoomConnection) {
      email = zoomConnection.email;
    }
  } catch (error) {
    logError({ error });
  }
  return email;
}

export default handleFetchUserZoomEmail;
