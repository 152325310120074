import { fromJS } from 'immutable';

import { actionTypes } from 'Modules/App/actions';
import { actionTypes as snackbarActionTypes } from 'Modules/Shared/actions/snackbar';

const initialState = fromJS({
  location: {},
  isLoading: true,
  isSnackbarOpen: false,
  snackbarQueue: [],
});

export default function app(state = initialState, action = {}) {
  switch (action.type) {
    case actionTypes.SET_LOCATION:
      return state.set('location', fromJS(action.payload));
    case actionTypes.SET_IS_LOADING:
      return state.set('isLoading', action.payload);

    case snackbarActionTypes.SET_SNACKBAR_OPEN: {
      return state.merge({ isSnackbarOpen: true });
    }
    case snackbarActionTypes.SET_SNACKBAR_CLOSED: {
      return state.merge({ isSnackbarOpen: false });
    }
    case snackbarActionTypes.PUSH_SNACKBAR_MESSAGE: {
      const changedSnackbarQueue = state.get('snackbarQueue').push(fromJS(action.payload));
      return state.merge({ snackbarQueue: changedSnackbarQueue });
    }
    case snackbarActionTypes.SHIFT_SNACKBAR_MESSAGE: {
      const changedSnackbarQueue = state.get('snackbarQueue').shift();
      return state.merge({ snackbarQueue: changedSnackbarQueue });
    }

    default:
      return state;
  }
}
