import {
  Record,
} from 'immutable';

const defaults = {
  id: null,
  name: '',
  email: '',
  profileImageURL: '',
  organizationId: null,
  timeZoneName: '',
};

class User extends Record(defaults) {
  static fromHTTPResponse(data) {
    return new User({
      id: data.id,
      name: data.name,
      email: data.email,
      profileImageURL: data.profileImageUrl,
      organizationId: data.orgId,
      timeZoneName: data.timeZone,
    });
  }
}

export default User;
