import {
  selectors,
} from '@groove-labs/groove-ui';
import {
  createSelector,
} from 'reselect';
import {
  OrderedMap,
} from 'immutable';

import {
  DOUBLE_BOOKABLE_TOGGLE_KEY_PATH,
  INSERT_AS_PLAIN_TEXT_TOGGLE_KEY_PATH,
  PROPOSALS_GROUP_ID,
} from 'Modules/Proposals/constants';
import {
  getDailyPlainTextTimeSlotGroups,
  getDailyTimeSlotGroups,
} from 'Modules/Proposals/utils';


const {
  makeGetFieldValue,
  makeIsFieldValid,
  groupData,
} = selectors.form;

const {
  getProperty,
} = selectors.ui;

export const makeGetPreviousFieldValue = (fieldId) => {
  return createSelector(
    [groupData],
    (group) => {
      if (!group) {
        return '';
      }
      return group.getIn(['fields', fieldId, 'previousValue']);
    },
  );
};

const userEventsSelector = state => state.getIn(['proposals', 'userEvents']);
const freeTimeSlotsSelector = state => state.getIn(['proposals', 'timeSlots', 'free']);
const overlappingTimeSlotsSelector = state => state.getIn(['proposals', 'timeSlots', 'overlapping']);
const isTitleValid = state => state.getIn(['form', PROPOSALS_GROUP_ID, 'fields', 'title', 'isValid']);
const createdMeetingProposalSelector = state => state.getIn(['proposals', 'createdMeetingProposal']);
const createdMeetingProposalTimeZoneNameSelector = state => state.getIn(['proposals', 'createdMeetingProposal', 'timeZoneName']);

export const getIsDoubleBooked = state => getProperty(state, DOUBLE_BOOKABLE_TOGGLE_KEY_PATH);
export const getInsertAsPlainText = state => getProperty(state, INSERT_AS_PLAIN_TEXT_TOGGLE_KEY_PATH);

export const getCurrentUser = state => state.getIn(['proposals', 'currentUser']);
export const getAllUsers = state => state.getIn(['proposals', 'allUsers']);
export const getSender = state => state.getIn(['proposals', 'sender']);
export const getOtherAttendeeIds = state => state.getIn(['proposals', 'otherAttendeeIds']);
export const getMeetingTypes = state => state.getIn(['proposals', 'meetingTypes']);
export const getCurrentMeetingType = state => state.getIn(['proposals', 'currentMeetingType']);
export const getRecipients = state => state.getIn(['proposals', 'recipients']);

export const getUserEvents = createSelector(
  userEventsSelector,
  events => events.valueSeq().toList(),
);

export const getEnabledMeetingTypes = createSelector(
  getMeetingTypes,
  meetingTypes => meetingTypes.filter(v => v.enabled),
);

export const getAllTimeSlots = createSelector(
  freeTimeSlotsSelector,
  overlappingTimeSlotsSelector,
  (timeSlots, overlappingTimeSlots) => timeSlots.concat(overlappingTimeSlots).valueSeq().toList(),
);

export const getUnavailableTimeSlots = createSelector(
  overlappingTimeSlotsSelector,
  getIsDoubleBooked,
  (overlappingTimeSlots, isDoubleBooked) => (isDoubleBooked ? new OrderedMap() : overlappingTimeSlots),
);

export const getAvailableTimeSlots = createSelector(
  freeTimeSlotsSelector,
  getAllTimeSlots,
  getIsDoubleBooked,
  (timeSlots, allTimeSlots, isDoubleBooked) => (isDoubleBooked ? allTimeSlots : timeSlots.valueSeq().toList()),
);

export const getIsCreatingMeetingProposal = state => state.getIn(['proposals', 'isCreatingMeetingProposal']);

export const getTitle = makeGetFieldValue('title');
export const getLocation = makeGetFieldValue('location');
export const getVideoConferencing = makeGetFieldValue('videoConferencing');
export const getDescription = makeGetFieldValue('description');
export const getTimeZone = makeGetFieldValue('timezone');
export const getDuration = makeGetFieldValue('duration');
export const getPreviousDuration = makeGetPreviousFieldValue('duration');
export const getIsValidTitle = makeIsFieldValid('title');

export const getGroupedTimeSlots = createSelector(
  getAllTimeSlots,
  createdMeetingProposalTimeZoneNameSelector,
  (timeSlots, timeZoneName) => timeSlots
    .sort((event1, event2) => event1.start.diff(event2.start))
    .groupBy(timeSlots => timeSlots.start.tz(timeZoneName).format('dddd, MMM DD')),
);

export const showCreateMeetingProposalButton = createSelector(
  isTitleValid,
  getSender,
  getAvailableTimeSlots,
  getIsCreatingMeetingProposal,
  (
    validTitle,
    sender,
    timeSlots,
    isCreatingMeetingProposal,
  ) => validTitle && sender.id != null && timeSlots.size > 0 && !isCreatingMeetingProposal,
);

export const getCreatedMeetingProposalTimeSlotGroups = createSelector(
  createdMeetingProposalSelector,
  proposal => getDailyTimeSlotGroups(proposal.get('timeSlots')),
);

export const getCreatedMeetingProposalTimeSlotGroupsPlainText = createSelector(
  createdMeetingProposalSelector,
  proposal => getDailyPlainTextTimeSlotGroups(proposal.get('timeSlots')),
);

// break up time slot groups into further groups of 3
export const getTimeSlotGroupTableRows = createSelector(
  getCreatedMeetingProposalTimeSlotGroups,
  groups => groups.entrySeq().toList().groupBy((v, k) => Math.ceil((k + 1) / 3)),
);

export const getPlainTextTimeSlotGroupTableRows = createSelector(
  getCreatedMeetingProposalTimeSlotGroupsPlainText,
  groups => groups.entrySeq().toList().groupBy((v, k) => Math.ceil((k + 1) / 3)),
);

const getCreatedMeetingProposal = state => state.getIn(['proposals', 'createdMeetingProposal']);

export const getCreatedMeetingProposalTimeZoneName = createSelector(
  getCreatedMeetingProposal,
  createdMeetingProposal => createdMeetingProposal.get('timeZoneName'),
);

export const getCreatedMeetingProposalSender = createSelector(
  getCreatedMeetingProposal,
  createdMeetingProposal => createdMeetingProposal.get('sender'),
);

export const getCreatedMeetingProposalId = createSelector(
  getCreatedMeetingProposal,
  createdMeetingProposal => createdMeetingProposal.get('id'),
);


export const getMeetingSlug = state => state.getIn(['proposals', 'meetingSlug']);
export const getComposeWidgetId = state => state.getIn(['proposals', 'composeWidgetId']);
export const getUserColors = state => state.getIn(['proposals', 'userColors']);
export const getUserZoomEmail = state => state.getIn(['proposals', 'userZoomEmail']);
export const getCurrentTimeRange = state => state.getIn(['proposals', 'currentTimeRange']);
export const getIsFetchingEvents = state => state.getIn(['proposals', 'isFetchingEvents']);

export const getOrganizationBrand = state => state.getIn(['proposals', 'organizationBrand']);
