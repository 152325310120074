import { eventChannel } from 'redux-saga';
import {
  cancel, take, put, select, fork,
} from 'redux-saga/effects';

import history from 'Utils/history';
import { setLocation } from 'Modules/App/actions';
import match from 'Routing/matcher';
import { getLocation } from 'Modules/App/selectors';

export default function* root() {
  const historyChannel = eventChannel((emitter) => {
    let initialLocation;

    if (typeof history.getCurrentLocation === 'function') {
      initialLocation = history.getCurrentLocation();
    } else {
      initialLocation = history.location;
    }

    if (initialLocation) {
      emitter(initialLocation);
    }

    return history.listen((location) => {
      emitter(location);
    });
  });

  yield put(setLocation(history.location));

  let currentSaga = null;

  while (true) {
    const currentLocation = yield select(getLocation);
    const currentRoute = match(currentLocation.get('pathname'));

    if (currentRoute) {
      if (currentSaga) {
        yield cancel(currentSaga);
      }
      currentSaga = yield fork(currentRoute.sagas);
    }
    const location = yield take(historyChannel);

    yield put(setLocation(location));
  }
}
