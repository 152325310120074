import {
  Record,
} from 'immutable';

const defaults = {
  id: null,
  name: '',
  enabled: false,
  subject: '',
  customLink: '',
  duration: 30 * 60,
  location: '',
  description: '',
  videoConferencing: null,
};

export default class MeetingType extends Record(defaults) {
  constructor(props = {}) {
    super({
      ...props,
      // we want these to default to empty string rather than null
      // so that they satisfy the meeting proposal schema
      subject: props.subject || defaults.subject,
      location: props.location || defaults.location,
      description: props.description || defaults.description,
    });
  }
}
