import { OrderedMap } from 'immutable';

const hasOverlap = (event1, event2) => event1.start.isBefore(event2.end) && event2.start.isBefore(event1.end);
const overlapsWithTimeSlot = timeSlot => event => hasOverlap(event, timeSlot);

const identifyTimeSlots = ({
  timeSlots, userEvents,
}) => {
  const busyUserEvents = userEvents.filter(userEvent => userEvent.transparency === 'opaque');

  return timeSlots.reduce((acc, timeSlot) => {
    if (busyUserEvents.some(overlapsWithTimeSlot(timeSlot))) {
      acc.overlappingTimeSlots = acc.overlappingTimeSlots.set(timeSlot.id, timeSlot);
    } else {
      acc.freeTimeSlots = acc.freeTimeSlots.set(timeSlot.id, timeSlot);
    }
    return acc;
  }, {
    freeTimeSlots: new OrderedMap(),
    overlappingTimeSlots: new OrderedMap(),
  });
};

export default identifyTimeSlots;
