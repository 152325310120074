import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as cx from 'classnames';
import {
  Input, selectors,
} from '@groove-labs/groove-ui';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';

import { getVideoConferencing } from 'Modules/Proposals/selectors';

const { makeGetFieldValue } = selectors.form;

const styles = {
  button: {
    color: '#0960B2',
    paddingTop: 4,
    marginBottom: 6,
  },
  buttonDisabled: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  inputTextStyle: {
    fontSize: 14,
    fontWeight: 'normal',
    height: 20,
  },
};

@connect(
  (state, { groupId }) => ({
    value: makeGetFieldValue('location')(state, { groupId }),
    videoConferencingValue: getVideoConferencing(state, { groupId }),
  }),
  {},
)
@withStyles(styles)
export default class LocationInput extends Component {
  static propTypes = {
    classes: PropTypes.shape({
      button: PropTypes.string.isRequired,
      buttonDisabled: PropTypes.string.isRequired,
      inputTextStyle: PropTypes.string.isRequired,
    }).isRequired,
    groupId: PropTypes.string.isRequired,

    initialValue: PropTypes.string,
    value: PropTypes.string,
    videoConferencingValue: PropTypes.string,
  };

  static defaultProps = {
    initialValue: null,
    value: null,
    videoConferencingValue: '',
  };

  constructor() {
    super();
    this.state = {
      inputDisabled: true,
    };
    this.inputRef = null;
  }

  handleEnableInput = () => {
    const { value } = this.props;

    this.setState({ inputDisabled: false }, () => {
      if (this.inputRef && !this.state.inputDisabled && !value) {
        this.inputRef.focus();
      }
    });
  };

  render() {
    const {
      classes, groupId, initialValue, value, videoConferencingValue,
    } = this.props;
    const { inputDisabled } = this.state;
    const isButtonDisabled = !!(value || initialValue || !videoConferencingValue);
    const placeholderText = videoConferencingValue
      ? 'This will populate with the video conferencing link.'
      : 'Add a location.';
    return (
      <React.Fragment>
        <Input
          fieldId="location"
          groupId={groupId}
          initialValue={initialValue}
          muiProps={{
            disabled: inputDisabled && !value && videoConferencingValue,
            placeholder: placeholderText,
            classes: { input: classes.inputTextStyle },
            inputRef: (ref) => {
              this.inputRef = ref;
            },
          }}
          validateBeforeRegistration
          hideErrorHelperTextBlock
        />
        <Typography variant="caption">
          <ButtonBase
            disabled={isButtonDisabled}
            className={cx({
              [classes.button]: true,
              [classes.buttonDisabled]: isButtonDisabled,
            })}
            onClick={this.handleEnableInput}
          >
            Replace conference link with another location
          </ButtonBase>
        </Typography>
      </React.Fragment>
    );
  }
}
