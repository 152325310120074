import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  connect,
} from 'react-redux';
import momentTimezone from 'moment-timezone/moment-timezone';

import TimeSlotPlainText from 'Modules/Shared/emailComponents/TimeSlotPlainText';
import CreatedTimeSlot from 'Modules/Proposals/data/CreatedTimeSlot';
import {
  getCreatedMeetingProposalId,
  getCreatedMeetingProposalTimeZoneName,
  getCreatedMeetingProposalSender,
  getOrganizationBrand,
  getPlainTextTimeSlotGroupTableRows,
} from 'Modules/Proposals/selectors';

const styles = theme => ({
  root: {
    width: 500,
    userSelect: 'none',
    border: '1px solid rgba(0,0,0,0.19)',
    borderRadius: 2,
    position: 'relative',
  },

  banner: {
    height: 8,
    width: 500,
    backgroundColor: theme.palette.primary.main,
  },

  image: {
    paddingBottom: 8,
  },

  header: {
    padding: 16,
  },

  meetingWithTypography: {
    paddingBottom: 8,
  },

  tableCell: {
    verticalAlign: 'top',
  },
});

const mapStateToProps = state => ({
  organizationBrand: getOrganizationBrand(state),
  proposalId: getCreatedMeetingProposalId(state),
  sender: getCreatedMeetingProposalSender(state),
  timeSlotGroupTableRows: getPlainTextTimeSlotGroupTableRows(state),
  timeZoneName: getCreatedMeetingProposalTimeZoneName(state),
});

const TimeSlotsPlainText = ({
  classes,
  proposalId,
  timeSlotGroupTableRows,
  timeZoneName,
}) => (
  <div
    id="groove-scheduler-time-slots"
    className={classes.root}
    data-meeting-proposal-id={proposalId}
  >
    These time slots are in {momentTimezone.tz(timeZoneName).format('z')}.
    {
      timeSlotGroupTableRows.map(tableRow => (
        <div>
          {tableRow.map(([day, timeSlots]) => (
            <div>
              {day}
              <div
                key={day}
                className={classes.tableCell}
              >
                {timeSlots.map(timeSlot => (
                  <TimeSlotPlainText
                    key={timeSlot.start.tz(timeZoneName).toString()}
                    timeSlot={timeSlot}
                  />
                )).valueSeq()}
              </div>
            </div>
          )).valueSeq()}
        </div>
      )).valueSeq()
    }
  </div>
);

TimeSlotsPlainText.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  proposalId: PropTypes.number.isRequired,
  timeSlotGroupTableRows: ImmutablePropTypes.orderedMapOf(
    ImmutablePropTypes.listOf(
      PropTypes.arrayOf(
        PropTypes.oneOf([
          PropTypes.string.isRequired,
          ImmutablePropTypes.listOf(CreatedTimeSlot),
        ]),
      ),
    ),
    PropTypes.number.isRequired,
  ).isRequired,
  timeZoneName: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(TimeSlotsPlainText));
