import {
  Record,
} from 'immutable';
import momentTimezone from 'moment-timezone';

const defaults = {
  start: momentTimezone(),
  end: momentTimezone(),
};

class TimeSlot extends Record(defaults) {
  get id() {
    return `${this.start.valueOf()}-${this.end.valueOf()}`;
  }

  toJS = () => ({
    id: this.id,
    start: this.start,
    end: this.end,
  })
}

export default TimeSlot;
