import React from 'react';
import {
  connect,
} from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

import {
  triggerShowReportDialog,
} from 'Modules/Error/actions';

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  triggerShowReportDialog,
};

const SubmitFeedbackButton = ({ triggerShowReportDialog }) => (
  <Button
    variant="raised"
    color="primary"
    onClick={triggerShowReportDialog}
  >
    Submit Feedback
  </Button>
);

SubmitFeedbackButton.propTypes = {
  triggerShowReportDialog: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SubmitFeedbackButton);

