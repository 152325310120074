import ruta3 from 'ruta3';

import routes from './routes';

const matcher = ruta3();

Object.entries(routes).forEach(([pattern, route]) => matcher.addRoute(pattern, route));


const match = (path) => {
  let route = null;
  try {
    route = matcher.match(path);
  } catch (e) {
    throw new Error(`Failed to find route definition for path '${path}'. Check routing configuration at src/js/routing/configuration: ${e}`);
  }
  return route.action;
};

export default match;
